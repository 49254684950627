@import "../../common.scss";

.contactus_sec1 {
  margin: 9em 0 4em;

  .adjusted {
    padding-left: 2em;
  }

  .left_section {
    position: relative;
    max-width: 100%;
    max-height: 100%;

    .background_img {
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      // height: 88%;
      // height: 51.75em;

      img {
        // max-width: 41.875em;
        width: 100%;
        // max-height: 51.5em;
        height: 100%;
      }
    }

    .text_container {
      padding: 4em 5em 4em 4em;
      z-index: 1;
      color: #fff;
      width: 100%;
      .top_section {
        // margin-bottom: 3.875em;
        margin-bottom: 2.5em;
      }
      .text_heading {
        font-size: 1.375em;
        font-weight: bold;
        margin-bottom: 0.727em;
        padding-left: 2.5em;
      }
      .icon_desc {
        display: flex;
        align-items: flex-start;
        margin-bottom: 3.875em;

        .phone_icon {
          max-width: 1.763em;
          padding-top: 0.5em;
        }

        .phone_box {
          margin-left: 1.743em;
          display: flex;
          flex-direction: column;

          .phone_desc {
            @extend %leftDescStyle;

            a {
              color: #fff;
            }
          }
        }
      }

      .icon_desc1 {
        display: flex;
        align-items: flex-start;
        // margin-bottom: 3.875em;
        // margin-bottom: 2.75em;
        margin-bottom: 2em;

        .phone_icon {
          max-width: 1.763em;
          padding-top: 0.5em;
        }

        .phone_box {
          margin-left: 1.743em;
          display: flex;
          flex-direction: column;

          .phone_desc {
            @extend %leftDescStyle;
            margin-bottom: 0;

            a {
              color: #fff;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .right_section {
    .background_img {
      position: absolute;
      top: -5.5em;
      right: 5em;
      z-index: -2;
      height: 100%;
      img {
        max-width: 79.25em;
        height: 100%;
        min-height: 63em;
      }
    }

    .text_container {
      padding: 1em 7.5em 5em 5em;
      z-index: 1;

      .contact_head {
        @extend %rightHeadStyle;
      }

      .contact_desc {
        @extend %rightDescStyle;
      }

      .form_container {
        .no_padding {
          padding-left: 0;
        }

        .error {
          text-transform: capitalize;
          color: red;
        }

        .material-textfield {
          position: relative;
          margin-top: 2.5em;
          background-color: transparent;

          .dropwdown_icon {
            margin-left: -1em;
          }

          .mobile_no_flex {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            align-items: flex-end;
            position: relative;

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            input[type="number"] {
              appearance: textfield;
              -moz-appearance: textfield;
            }

            .cc_box {
              max-width: 20%;
              font-size: 1.125em;
              letter-spacing: 0.24px;
              font-weight: 500;
              position: relative;

              .cc_dd {
                border: none;
                background-color: $color-white;
                padding: 0 1em 0.4em 0;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4);
                color: #333333;
              }

              .dropwdown_icon {
                position: absolute;
                top: 0.6em;
                right: 0;
                max-width: 0.563em;
              }
            }

            .no_box {
              width: 100%;
              max-width: 95%;
              position: relative;

              input {
                position: relative;
                max-width: 85%;
                margin-left: 1em;
                padding: 0.5em 0 0.5em 0.3em;
              }

              label {
                left: 1em;
              }
            }

            .error {
              position: absolute;
              bottom: -1.5em;
              text-transform: capitalize;
            }
          }
        }

        .check_box {
          display: flex;
          justify-content: flex-start;
          margin-top: 1em;
          .check_box_selector {
            appearance: auto;
            -webkit-appearance: auto;
            width: initial;
            margin-right: 0.5em;
            accent-color: #fe0103;
            cursor: pointer;
            // width: 1.05em;
            // height: 1.05em;
          }
          .check_box_label {
            position: relative;
            margin: 0;
            padding: 0;
            transform: none;
            color: #000000;
            vertical-align: text-bottom;
            // transform: translateY(-13%) scale(1);
          }
        }
        // input[type=checkbox]:checked {
        //   background-color: red;
        // }
        .check_box_selector:focus + .check_box_label {
          transform: none;
          font-size: 1.125em;
        }

        label {
          position: absolute;
          font-size: 1.125em;
          font-weight: normal;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: transparent;
          color: gray;
          padding: 0 0.3em;
          margin: 0 0.3em;
          transition: 0.1s ease-out;
          transform-origin: left top;
          pointer-events: none;
          opacity: 0.8;
        }

        // .enquiry_text_label {
        //   position: absolute;
        //   top: 17%;
        //   display: block;
        // }

        .select_input {
          background-image: url("../../images/contactus/Polygon\ 15.svg");
          background-repeat: no-repeat;
          background-position: calc(100% - 0.8em) center;
          background-size: 0.625em;
          cursor: pointer;
        }

        input {
          font-size: 1.5em;
          letter-spacing: 0.24px;
          font-weight: 500;
          outline: none;
          border: none;
          background-color: transparent;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
          padding: 0.7em 0 0.3em 0.3em;
          color: $color-primary;
          transition: 0.1s ease-out;
          width: 100%;
          max-width: 95%;
        }

        .enquiry_text {
          font-size: 1.5em;
          letter-spacing: 0.24px;
          font-weight: 500;
          outline: none;
          border: none;
          background-color: transparent;
          // border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
          border: 0.5px solid rgba(0, 0, 0, 0.3);
          padding: 0.7em 0 0.3em 0.3em;
          color: $color-primary;
          transition: 0.1s ease-out;
          width: 100%;
          max-width: 95%;
          resize: none;
        }

        .enquiry_text::placeholder {
          font-size: 0.75em;
          font-weight: normal;
          padding: 0 0.3em;
          opacity: 0.8;
        }

        // .enquiry_text:focus + .enquiry_text_label {
        //   display: none;
        // }

        input:focus {
          border-color: $color-primary;
          border-bottom: 1px solid rgba(0, 0, 0, 1);
        }

        input:focus + label {
          color: $color-primary;
          top: 0;
          font-size: 0.875em;
          transform: translateY(-50%) scale(0.9);
          transition: all 0.3s ease-in;
        }

        input:not(:placeholder-shown) + label {
          top: 0;
          transform: translateY(-50%) scale(0.9);
        }

        .input {
          font-size: 1.05em;
          outline: none;
          border: none;
          background-color: transparent;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
          padding: 1rem 0.7rem;
          color: $color-primary;
          transition: 0.1s ease-out;
          width: 100%;
          max-width: 95%;
        }

        .input:focus {
          border-color: $color-primary;
          border-bottom: 1px solid rgba(0, 0, 0, 1);
        }

        .input:focus + label {
          color: $color-primary;
          top: 0;
          transform: translateY(-50%) scale(0.9);
        }

        .input:not(:placeholder-shown) + label {
          top: 0;
          transform: translateY(-50%) scale(0.9);
        }

        .contact_email {
          max-width: 100%;
        }

        .contactus {
          display: inline-block;
          min-width: 50%;

          input {
            max-width: 90%;
          }
        }

        .emailid {
          display: inline-block;
          min-width: 50%;

          input {
            max-width: 90%;
          }
        }

        .cta_wrapper {
          margin: 4em 0;
          color: $color-white;

          button {
            outline: none;
            border: none;
          }

          .send {
            @include textStyles(1.1em, 1em, 0.2px);
            width: 100%;
            max-width: 12.5em;
            height: 3em;
            padding: 1em 0em;
            outline: 0;
            background-color: transparent;
            text-transform: capitalize;
            opacity: 1;
            text-decoration: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 2em;
            color: $color-white;
            background-color: $color-secondary;
            transition: all 0.3s ease-in;
            box-shadow: 0 4px 12px var(--shadow);
            transform: translateY(var(--y));
            transition: box-shadow 0.3s ease, transform 0.3s ease;

            &:hover {
              --y: -2px;
              box-shadow: 0 8px 16px var(--shadow);
            }

            &:active {
              --y: 1px;
              box-shadow: 0 4px 8px var(--shadow);
            }

            img {
              margin-left: 1em;
              max-width: 1.8em;
            }
          }
        }
      }
    }
  }
}

.contactus_sec2 {
  margin: 8.688em 0 0;
  line-height: 0;

  .map_head {
    margin: 1em 0 1.2em;
    font-size: 2.5em;
    font-weight: 700;
    text-align: center;
  }
}

@media (max-width: 2561px) {
  .contactus_sec1 {
    .hollow_title {
      z-index: -3;
      position: absolute;
      left: -1.85em;
      top: 1.2em;
      font-size: 13.063em;
      transform: rotate(-90deg);
    }
  }
}

@media (max-width: 992px) {
  .contactus_sec1 {
    margin: 4em 0 1em;

    .adjusted {
      padding-left: 0;
    }

    .hollow_title {
      top: 0;
      left: 0;
      display: flex;
      text-align: center;
      justify-content: center;
      font-size: 10em;
      position: relative;
      transform: rotate(0deg);
    }

    .left_section {
      .background_img {
        left: 10em;
        height: 100%;
      }

      .text_container {
        padding: 4em 14.5em 5em 14em;
      }
    }

    .right_section {
      .background_img {
        top: 0;
        left: 0em;
        height: 100%;
        img {
          max-width: 62.25em;
          height: 100%;
        }
      }

      .text_container {
        // padding: 2.5em 7.5em 5em 5em;
        padding: 2.5em 7.5em 2em 5em;

        .form_container {
          .material-textfield {
            .mobile_no_flex {
              .no_box {
                padding-right: 15px;
                max-width: 100%;

                input {
                  max-width: 95%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 835px) {
  .contactus_sec1 {
    .left_section {
      .background_img {
        // left: 4em;
        left: 2.5em;
      }
    }
  }
}

@media (max-width: 767px) {
  .contactus_sec1 {
    margin: 0em 0 1em;

    .hollow_title {
      top: 0;
      left: 0;
      display: flex;
      text-align: center;
      justify-content: center;
      font-size: 6em;
      position: relative;
      transform: rotate(0deg);
    }

    .left_section {
      .background_img {
        left: 0;
      }

      .text_container {
        padding: 3em 2em;
        width: 100%;

        .top_section {
          margin-bottom: 2.2em;
        }

        // .icon_desc {
        //   margin-bottom: 2.2em;

        //   .phone_box {
        //     .phone_desc {
        //       font-size: 1.3em !important;
        //     }
        //   }
        // }

        .icon_desc1 {
          margin-bottom: 2.2em;

          .phone_box {
            .phone_desc {
              font-size: 1.3em !important;
            }
          }
        }
      }
    }

    .right_section {
      .background_img {
        top: -2em;
        left: 0;
      }

      .text_container {
        padding: 0;

        .contact_head {
          text-align: center;
          line-height: 1em !important;
          font-size: 2.4em !important;
        }

        .contact_desc {
          text-align: center;
          line-height: 1em !important;
          font-size: 1.3em !important;
        }

        .form_container {
          .check_box {
            align-items: flex-start;
            .check_box_label {
              font-size: 1.2em;
            }
            .check_box_selector {
              margin-top: 0.25em;
            }
            .check_box_selector:focus + .check_box_label {
              font-size: 1.2em;
            }
          }
          .no_padding {
            padding: 0;
          }

          input {
            font-size: 1.4em;
          }

          label {
            font-size: 1.3em;
          }

          .enquiry_text::placeholder {
            font-size: 0.85em;
          }

          .input {
            font-size: 1.3em;
          }

          .cta_wrapper {
            display: flex;
            justify-content: center;
            margin: 5.6em 0 3.2em 0;
          }
        }
      }
    }
  }

  .contactus_sec2 {
    margin-top: 3em;
    .map_head {
      font-size: 2.4em;
      font-weight: 600;
      margin: 1.2em 0 0.7em;
    }
  }
}

@media (max-width: 374px) {
  .contactus_sec1 {
    .left_section {
      .text_container {
        padding: 2em 1em;
        font-size: 0.99em;

        .icon_desc {
          margin-bottom: 1.2em;

          .phone_box {
            .phone_desc {
              line-height: 1.7em;
              margin: 0;
            }
          }
        }
      }

      .background_img img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .right_section {
      .background_img img {
        max-width: 100%;
      }
    }
  }
}

%leftDescStyle {
  font-size: 1.375em;
  line-height: 1.7em;
  letter-spacing: 0.22px;
  font-family: "Poppins";
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  text-transform: initial;
}

%rightHeadStyle {
  font-size: 2.5em;
  line-height: 1.7em;
  letter-spacing: 0.4px;
  font-family: "Poppins";
  font-weight: 600;
  color: #1d1d1d;
  opacity: 1;
  text-transform: capitalize;
}

%rightDescStyle {
  font-size: 1.25em;
  line-height: 1.7em;
  letter-spacing: 0px;
  font-family: "Poppins";
  font-weight: 400;
  color: #1d1d1d;
  opacity: 1;
  text-transform: initial;
}
