@import "../../common.scss";

.pagenotfoundsec {
  overflow-x: hidden;
  position: relative;
  text-align: center;
  padding: 10em 0;
  .notfound_container {
    display: flex;
    justify-content: center;
  }
  .hollow_title {
    font-size: 21.313em;
    position: absolute;
    z-index: 0;
  }
  .pagenotimg1 {
    z-index: 1;
    margin-top: 4.5em;
    max-width: 32.438em;
  }
  .headp {
    text-align: center;
    .heading {
      font-size: 2.813em;
      line-height: 1.5em;
      font-weight: 500;
      color: $color-primary;
      opacity: 1;
      font-weight: 600;
      letter-spacing: 0.45px;
    }
    p {
      font-size: 1.25em;
      line-height: 1.7em;
      letter-spacing: 0.24px;
      font-weight: 500;
      color: $color-primary;
      text-transform: uppercase;
      opacity: 0.8;
    }
    .link_p {
      font-size: 1.25em;
      line-height: 1.7em;
      letter-spacing: 0.24px;
      font-weight: 500;
      color: $color-primary;
      text-transform: initial;
      opacity: 0.8;
    }
    .mobilep {
      display: none;
    }

    .orange_link {
      color: $color-secondary;
      cursor: pointer;
      &:hover {
        color: $color-secondary;
      }
    }
    .back_button {
      //   position: absolute;
      top: 70%;
      right: 45.5%;
      border: 1px solid #231f20;
      background-color: transparent;
      font-size: 1em;
      line-height: 1.1875em;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      padding: 1em 3em;
    }
  }
}

@media (max-width: 2560px) {
  .pagenotfoundsec {
    .hollow_title {
      right: 2.3em;
    }
  }
}
@media (max-width: 1920px) {
  .pagenotfoundsec {
    .hollow_title {
      right: 1.8em;
    }
  }
}
@media (max-width: 1600px) {
  .pagenotfoundsec {
    .hollow_title {
      right: 1.75em;
    }
  }
}
@media (max-width: 1460px) {
  .pagenotfoundsec {
    .hollow_title {
      right: 1.7em;
    }
  }
}
@media (max-width: 1400px) {
  .pagenotfoundsec {
    .hollow_title {
      right: 1.9em;
    }
  }
}
@media (max-width: 1300px) {
  .pagenotfoundsec {
    .hollow_title {
      right: 1.85em;
    }
  }
}

@media (max-width: 992px) {
  .pagenotfoundsec {
    .hollow_title {
      right: 0.73em;
    }
  }
}
@media (max-width: 768px) {
  .pagenotfoundsec {
    .hollow_title {
      right: 0.6em;
    }
  }
}

@media (max-width: 600px) {
  .pagenotfoundsec {
    padding: 22em 0 12em;
    .hollow_title {
      font-size: 18em;
      right: 0.05em;
    }
    .pagenotimg1 {
      margin-top: 4em;
      max-width: 28.438em;
    }
    .headp {
      .heading {
        font-size: 2em;
        margin-bottom: 1.5em;
      }
      p {
        font-size: 1.6em;
        padding: 0 2.5em;
        margin-bottom: 1em;
      }
      .link_p {
        font-size: 1.6em;
        padding: 0;
      }
    }
  }
}

@media (max-width: 374px) {
  .pagenotfoundsec {
    .hollow_title {
      font-size: 14em !important;
      right: 0.1em;
    }
    .pagenotimg1 {
      margin-top: 3em;
      max-width: 26.438em;
    }
  }
}
