/*============================
    General CSS Starts Here   
=============================*/

$color-primary: #333333;
$color-secondary: #fe0103;
$color-white: #ffffff;

::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $color-primary;
}

// html {
//   scroll-behavior: smooth;
// }

body {
  font-family: "Poppins";
  background-color: transparent;
}

.row {
  padding: 0;
  margin: 0;
}

a,
a:active,
a:visited,
a:focus {
  text-decoration: none;

  &:hover {
    outline: none;
    text-decoration: none;
  }
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

ul,
ul:focus {
  border: none;
  outline: none;
}

li {
  list-style-type: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

option {
  cursor: pointer !important;
}

[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}

.hollow_title {
  font-size: 12.611em;
  text-align: center;
  // font-size: 13.063em;
  letter-spacing: 2.09px;
  font-family: "Poppins";
  font-weight: bold;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 0.15;
  opacity: 0.17;
  line-height: 1em;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-image: linear-gradient(
    to right,
    #1c1c1c,
    #1c1c1c 50%,
    transparent 50%
  ); */
  background-size: 200% 100%;
  background-position: 100%;
  transition: all 1s cubic-bezier(0, 0, 0.23, 1);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1c1c1c;
  display: inline-block;
  /* text-transform: capitalize; */
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.form_input {
  -webkit-appearance: none;
  outline: none;
  display: block;
  width: 100%;
  max-width: 95%;
  font-size: 1.5em;
  line-height: 1.4em;
  padding: 0.7em 0 0.3em 0.3em;
  background-color: transparent !important;
  color: $color-primary;
  letter-spacing: 0.24px;
  font-weight: 500;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
  transition: 0.1s ease-out;
  &::-webkit-input-placeholder {
    /* Edge */
    color: $color-primary;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-primary;
  }
  &::placeholder {
    color: $color-primary;
  }
}
.form_input:-webkit-autofill,
.form_input:-webkit-autofill:hover,
.form_input:-webkit-autofill:focus,
.form_input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}
// .swiper-slide {
//   -webkit-backface-visibility: hidden;
// }

.loader_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 50;
  background-color: #fff;

  .loader_img {
    width: 100%;
    max-width: 9.2em;
  }
  .loader_gif {
    width: 100%;
    max-width: 9.2em;
  }
}

.slider__prev,
.slider__next {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.main_img_wrap .swiper-button-disabled {
  opacity: 0.3;
  pointer-events: none;
  transition: 0.5s ease-in-out;
}


@media (min-width: 2500px) {
  .my_container {
    max-width: 2300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }

  .my_container {
    max-width: 2293.3px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}


@media (max-width: 1921px) {
  body {
    font-size: 16px;
  }

  .my_container {
    max-width: 1720px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1900px) {
  body {
    font-size: 14px;
  }

  .my_container {
    max-width: 1361.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1536px) {
  body {
    font-size: 13px;
  }
}

@media (max-width: 1440px) {
  body {
    // font-size: 13px;
    font-size: 12px;
  }

  .my_container {
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1366px) {
  body {
    // font-size: 13px;
    font-size: 11px;
  }

  .my_container {
    max-width: 1223.708px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  body {
    // font-size: 12px;
    font-size: 10.5px;
  }

  .my_container {
    max-width: 1146.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }

  .my_container {
    max-width: 1069.625px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 835px) {
  body {
    font-size: 11.5px;
  }

  .my_container {
    max-width: 747.125px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 10px;
  }
}

@media (max-width: 834px) {
  .hollow_title {
    font-size: 10.611em;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 374px) {
  .hollow_title {
    font-size: 5.9em !important;
  }
}

/* ====================
        MIXIN start here
======================*/

@mixin textStyles($fontSize, $lineHeight, $letterSpacing) {
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin fontStyles($fontFamily, $fontWeight) {
  font-family: $fontFamily, "Poppins";
  font-weight: $fontWeight;
}

/* ====================
        MIXIN end here
======================*/
