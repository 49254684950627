@import "../../common.scss";
.common_actual_count {
  @include textStyles(3.75em, 1.183em, 0.05em);
  font-weight: bolder;
  margin-bottom: 0.067em;
  color: $color-secondary;
}
.common_count_title {
  @include textStyles(1.125em, 1.5em, 0px);
  color: $color-primary;
}
@media (max-width: 767px) {
  .common_actual_count {
    @include textStyles(3em, 1.172em, 1.45px);
  }
  .common_count_title {
    @include textStyles(1.1em, 1.182em, 0px);
  }
}
