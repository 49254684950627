@import "../../common.scss";

.proinner_sec1 {
  margin: 5em 0;

  .col-lg-7 {
    padding: 0;
  }

  .slider {
    padding: 32px 32px 32px 0;
    color: #fff;

    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .slider__flex {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
    }

    .slider__col {
      display: flex;
      flex-direction: column;
      // width: 150px;
      margin-right: 3em;
    }

    .slider__prev,
    .slider__next {
      position: absolute;
      cursor: pointer;
      color: #000;
      text-align: center;
      font-size: 14px;
      //   height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .slider__prev:focus,
    .slider__next:focus {
      outline: none;
    }

    .slider__thumbs {
      height: calc(600px - 96px);
      // .swiper-slide {
      //   height: auto !important;
      // }
    }

    .slider__thumbs .slider__image {
      //   transition: 0.25s;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
    }

    .slider__thumbs .slider__image:hover {
      opacity: 1;
    }

    .slider__thumbs .swiper-slide-thumb-active .slider__image {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
      opacity: 1;
      border: 2px solid red;
    }

    .main_img_wrap {
      width: 100%;
      height: 100%;
      // height: 500px;
      position: relative;
      z-index: 0;
      // min-width: 40em;
    }

    .main_img_wrap .swiper-container {
      width: 100%;
      height: 100%;
    }

    .main_img_wrap .swiper-slide {
      height: 100% !important;
    }
    .main_img_wrap .slider__image img {
      transition: 3s;
    }

    .main_img_wrap .slider__image:hover img {
      transform: scale(1);
    }

    .slider__image {
      // width: 100%;
      // height: 100%;
      border-radius: 0.3em;
      // overflow: hidden;
    }
    .slider__image img {
      display: block;
      // width: 100%;
      // height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .thumb_img_wrap {
      .thumb_img {
        width: 100%;
        height: 100%;
        max-width: 9.013em;
        max-height: 9.013em;
      }
    }
    .product_img_wrap {
      .product_img {
        width: 100%;
        height: 100%;
      }
    }

    .swiper_navigations {
      .nav_icons {
        max-width: 4em;
      }

      .slider__prev {
        top: 45%;
        left: -5%;
        z-index: 2;
      }

      .slider__next {
        top: 45%;
        right: -5%;
        z-index: 2;
      }
    }
  }

  .text_container {
    padding-top: 3em;
    .product_name {
      @include textStyles(2rem, 1.5em, 0.45px);
      font-family: "Poppins";
      font-weight: 600;
      color: $color-primary;
      text-transform: capitalize;
    }

    .product_code {
      font-size: 1.5em;
    }

    .product_desc {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @include textStyles(2rem, 1.5em, 0.45px);
        font-family: "Poppins";
        font-weight: 600;
        color: $color-primary;
        margin: 0;
        strong {
          font-weight: inherit
        }
      }
    }

    .product_desc,
    ul {
      @include textStyles(1.125em, 1.7em, 0.24px);
      margin-top: 1.75em;
      // margin-bottom: 1.313em;
      margin-bottom: 1.4321em;
      color: $color-primary;
      padding-left: 0;
    }

    .product_features__heading {
      margin: 0 0 0.7em;
      padding: 0;
      @include textStyles(1.125em, 1.7em, 0.45px);
      font-weight: 600;
      color: $color-primary;
      opacity: 0.8;
    }

    .product_features {
      margin: 0;
      padding: 0;
    }

    .product_features__list,
    li {
      position: relative;
      @include textStyles(1em, 1.7em, 0.24px);
      font-weight: normal;
      color: $color-primary;
      opacity: 0.8;
      margin-left: 0.7em;
      margin-bottom: 0.3em;

      &::before {
        content: "•";
        position: absolute;
        color: $color-secondary;
        font-weight: 700;
        display: inline-block;
        width: 0.6em;
        margin-left: -0.7em;
        font-size: 1.3em;
      }
    }

    .cta_wrapper {
      position: absolute;
      width: 100%;
      // margin-top: 1.7em;
      color: $color-white;

      button {
        outline: none;
        border: none;
      }

      .enquire {
        @include textStyles(1em, 1em, 0.2px);
        width: 100%;
        max-width: 12em;
        height: 3em;
        padding: 1em 0em;
        outline: 0;
        background-color: transparent;
        text-transform: capitalize;
        text-decoration: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 2em;
        color: $color-white;
        background-color: $color-secondary;
        transition: all 0.3s ease-in;
        box-shadow: 0 4px 12px var(--shadow);
        transform: translateY(var(--y));
        transition: box-shadow 0.3s ease, transform 0.3s ease;

        &:hover {
          --y: -2px;
          box-shadow: 0 8px 16px var(--shadow);
        }

        &:active {
          --y: 1px;
          box-shadow: 0 4px 8px var(--shadow);
        }

        img {
          margin-left: 1em;
          max-width: 1.8em;
        }
      }
    }
  }
}

.proinner_sec2 {
  .morepro_heading {
    @include textStyles(2.5em, 1.5em, 0.45px);
    font-weight: 600;
    color: $color-primary;
    text-transform: capitalize;
  }

  .moreproduct_list {
    margin-top: 4.063em;
    position: relative;

    .swiper_navigations {
      position: absolute;
      top: -18%;
      right: 1%;
      display: flex;

      .nav_icons {
        max-width: 3.5em;
        cursor: pointer;
        color: #000;
        align-items: center;
        justify-content: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .product__prev {
        margin-right: 1em;
      }

      // .product__prev.active {
      //   transform: rotate(180deg);
      // }

      // .product__next {
      //   transform: rotate(180deg);
      // }

      // .product__next.active {
      //   transform: rotate(0deg);
      // }

      .product__prev:focus,
      .product__next:focus {
        outline: none;
      }
    }

    .product_details_container {
      padding: 0;

      .product_detail_wrapper {
        width: 100%;
        max-width: 23.813em;
        position: relative;
        overflow: hidden;
      }

      .product_detail_wrapper::before {
        content: "";
        width: 100%;
        background-color: #000;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: -2;
        transition: 0.3s opacity;
      }

      .imageanimate {
        overflow: hidden;
        border-radius: 3px;

        .content-overlay {
          overflow: hidden;
          background: rgba(0, 0, 0, 0.6);
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
          -webkit-transition: all 0.4s ease-in-out 0s;
          -moz-transition: all 0.4s ease-in-out 0s;
          transition: all 0.4s ease-in-out 0s;
          border-radius: 3px;
        }

        &:hover .content-overlay {
          opacity: 1;
        }
      }

      .product_img {
        width: 100%;
        border-radius: 3px;
        position: relative;
      }

      .text_container {
        // position: absolute;
        // bottom: 3.875em;
        bottom: -100%;
        left: 2.688em;
        z-index: 3;
        transition: 0.3s bottom;
      }

      .product_name {
        @include textStyles(1.333em, 1.5em, 0.24px);
        @include fontStyles("Poppins", 600);
        color: #333333;
        text-transform: capitalize;
        opacity: 1;
        margin-top: 2.556em;
      }

      .product_synopsis {
        @include textStyles(1.125em, 1.7em, 0.24px);
        font-family: "Poppins";
        margin-top: 1.6em;
        margin-bottom: 1.6em;
        width: 100%;
        color: #333333;
        opacity: 0.8;
      }

      .product_detail_wrapper:hover::before {
        opacity: 0.8;
      }

      .product_detail_wrapper:hover .text_container {
        bottom: 3.875em;
      }

      .product_cta_wrapper {
        margin-bottom: 6.25em;
        margin-top: 1.6em;
      }

      .horizontal_line {
        border-top: 1px solid black;
        margin-bottom: 3em;
        opacity: 0.35;
      }

      .loadmore_cta_wrapper {
        text-align: center;
        margin-bottom: 3.625em;
      }
    }
  }
}

.proinner_sec3 {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 7.063em 0 7.875em;

  .background {
    position: relative;
  }

  .form_backimg {
    position: absolute;
    max-width: 35em;
    z-index: -1;
    bottom: -17%;
    right: -15%;
  }

  .form_img {
    // max-width: 45.688em;
    max-width: 100%;
    max-height: 43.875em;
    z-index: 1;
  }

  .text_container {
    .form_heading {
      @include textStyles(2.5em, 1.5em, 0.45px);
      font-weight: 600;
      color: $color-primary;
      text-transform: capitalize;
    }

    .form_desc {
      @include textStyles(1.125em, 1.7em, 0.24px);
      color: $color-primary;
      max-width: 82%;
      opacity: 0.8;
    }
  }

  .form_container {
    .no_padding {
      padding-left: 0;
    }

    .error {
      position: absolute;
      color: red;
      text-transform: capitalize;
    }

    .material-textfield {
      position: relative;
      margin-top: 2.5em;
      background-color: transparent !important;

      .mobile_no_flex {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        position: relative;

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          appearance: textfield;
          -moz-appearance: textfield;
        }

        .cc_box {
          max-width: 18%;
          font-size: 1.125em;
          letter-spacing: 0.24px;
          font-weight: 500;
          position: relative;

          .cc_dd {
            border: none;
            background-color: transparent;
            padding: 0 1em 0.4em 0;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.4);
            color: #333333;
          }

          .dropwdown_icon {
            position: absolute;
            top: 0.6em;
            right: 0;
            max-width: 0.563em;
          }
        }

        .no_box {
          width: 100%;
          max-width: 95%;
          position: relative;

          input {
            position: relative;
            max-width: 85%;
            margin-left: 1em;
            padding: 0.5em 0 0.5em 0.3em;
          }

          label {
            left: 1em;
          }
        }
        .error {
          position: absolute;
          bottom: -1.5em;
          text-transform: capitalize;
        }
      }
    }

    label {
      position: absolute;
      font-size: 1.125em;
      font-weight: normal;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      color: gray;
      padding: 0 0.3em;
      margin: 0 0.3em;
      transition: 0.1s ease-out;
      transform-origin: left top;
      pointer-events: none;
      opacity: 0.8;
    }

    input,
    .textarea_text {
      appearance: none;
      -webkit-appearance: none;
      font-size: 1.5em;
      letter-spacing: 0.24px;
      font-weight: 500;
      outline: none;
      border: none;
      background-color: transparent !important;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
      padding: 0.7em 0 0.3em 0.3em;
      color: $color-primary;
      transition: 0.1s ease-out;
      width: 100%;
      max-width: 95%;
    }

    .textarea_text {
      border: 0.5px solid rgba(0, 0, 0, 0.3);
      resize: none;
    }

    .textarea_text::placeholder {
      font-size: 0.75em;
      font-weight: normal;
      padding: 0 0.3em;
      opacity: 0.8;
    }

    .select_input {
      background-image: url("../../images/contactus/Polygon\ 15.svg");
      background-repeat: no-repeat;
      background-position: calc(100% - 0.8em) center;
      background-size: 0.625em;
      cursor: pointer;
    }

    input:focus {
      border-color: $color-primary;
      border-bottom: 1px solid rgba(0, 0, 0, 1);
    }

    input:focus + label {
      color: $color-primary;
      top: 0;
      font-size: 0.875em;
      transform: translateY(-50%) scale(0.9);
      transition: all 0.3s ease-in;
    }

    input:not(:placeholder-shown) + label {
      top: 0;
      transform: translateY(-50%) scale(0.9);
    }

    .input {
      font-size: 1.05em;
      outline: none;
      border: none;
      background-color: transparent;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
      padding: 1rem 0.7rem;
      color: $color-primary;
      transition: 0.1s ease-out;
      width: 100%;
      max-width: 95%;
    }

    .input:focus {
      border-color: $color-primary;
      border-bottom: 1px solid rgba(0, 0, 0, 1);
    }

    .input:focus + label {
      color: $color-primary;
      top: 0;
      transform: translateY(-50%) scale(0.9);
    }

    .input:not(:placeholder-shown) + label {
      top: 0;
      transform: translateY(-50%) scale(0.9);
    }

    .contact_email {
      max-width: 100%;
    }

    .contactus {
      display: inline-block;
      min-width: 50%;

      input {
        max-width: 90%;
      }
    }

    .emailid {
      display: inline-block;
      min-width: 50%;

      input {
        max-width: 90%;
      }
    }

    .cta_wrapper {
      margin: 4em 0;
      color: $color-white;

      button {
        outline: none;
        border: none;
      }

      .send {
        @include textStyles(1.1em, 1em, 0.2px);
        width: 100%;
        max-width: 12.5em;
        height: 3em;
        padding: 1em 0em;
        outline: 0;
        background-color: transparent;
        text-transform: capitalize;
        opacity: 1;
        text-decoration: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 2em;
        color: $color-white;
        background-color: $color-secondary;
        transition: all 0.3s ease-in;
        box-shadow: 0 4px 12px var(--shadow);
        transform: translateY(var(--y));
        transition: box-shadow 0.3s ease, transform 0.3s ease;

        &:hover {
          --y: -2px;
          box-shadow: 0 8px 16px var(--shadow);
        }

        &:active {
          --y: 1px;
          box-shadow: 0 4px 8px var(--shadow);
        }

        img {
          margin-left: 1em;
          max-width: 1.8em;
        }
      }
    }
  }

  .cta_wrapper {
    margin: 4em 0;
    color: $color-white;

    button {
      outline: none;
      border: none;
    }

    .send {
      @include textStyles(1.2em, 1em, 0.2px);
      width: 100%;
      max-width: 12.5em;
      height: 3.5em;
      padding: 1em 0em;
      outline: 0;
      background-color: transparent;
      text-transform: capitalize;
      opacity: 0.8;
      text-decoration: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 2em;
      color: $color-white;
      background-color: $color-secondary;
      transition: all 0.3s ease-in;
      box-shadow: 0 4px 12px var(--shadow);
      transform: translateY(var(--y));
      transition: box-shadow 0.3s ease, transform 0.3s ease;

      &:hover {
        --y: -2px;
        box-shadow: 0 8px 16px var(--shadow);
      }

      &:active {
        --y: 1px;
        box-shadow: 0 4px 8px var(--shadow);
      }

      img {
        margin-left: 1em;
        max-width: 1.8em;
      }
    }
  }
  .form_checkbox {
    display: flex;
    justify-content: flex-start;
    margin-top: 1.5em;
    text-align: start;
    .check_box_selector {
      appearance: auto;
      -webkit-appearance: auto;
      width: initial;
      // margin-right: 0.75em;
      cursor: pointer;
      // vertical-align: sub;
      accent-color: #fe0103;
    }
    .check_box_label {
      position: relative;
      color: #333333;
      vertical-align: 10%;
    }
    input:not(:placeholder-shown) + label {
      top: initial;
      transform: translateY(0%) scale(1);
    }
  }
  .check_box_selector:focus + .check_box_label {
    // color: #333333;
    // top: initial;
    font-size: 1.125em;
    transform: none;
    // transition: all 0.3s ease-in;
  }
}

input {
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

@media (max-width: 2600px) {
  .proinner_sec1 {
    .slider {
      .swiper_navigations {
        .slider__prev {
          left: -3%;
        }

        .slider__next {
          right: -3%;
        }
      }
    }
  }

  .proinner_sec2 {
    .moreproduct_list {
      .swiper_navigations {
        right: 5%;
      }

      .product_details_container {
        .imageanimate {
          .content-overlay {
            max-height: 22.5em;
          }
        }
      }
    }
  }

  .proinner_sec3 {
    .form_backimg {
      right: -14%;
    }
  }
}

@media (max-width: 1920px) {
  .proinner_sec1 {
    .slider {
      .swiper_navigations {
        .slider__prev {
          left: -4.5%;
        }

        .slider__next {
          right: -4.5%;
        }
      }
    }
  }

  .proinner_sec2 {
    .moreproduct_list {
      .swiper_navigations {
        right: 1.1%;
      }
    }
  }
}

@media (max-width: 1600px) {
  .proinner_sec1 {
    .slider {
      .swiper_navigations {
        .slider__prev {
          left: -5.5%;
        }

        .slider__next {
          right: -5.5%;
        }
      }
    }
  }

  .proinner_sec2 {
    .moreproduct_list {
      .swiper_navigations {
        right: 0%;
      }
    }
  }

  .proinner_sec3 {
    .text_container {
      .form_desc {
        max-width: 85%;
      }
    }
  }
}

@media (max-width: 1460px) {
  .proinner_sec1 {
    .slider {
      .swiper_navigations {
        .slider__prev {
          left: -5.5%;
        }

        .slider__next {
          right: -5.5%;
        }
      }
    }
  }

  .proinner_sec2 {
    .moreproduct_list {
      .swiper_navigations {
        right: 0%;
      }
    }
  }
}

@media (max-width: 1370px) {
  .proinner_sec2 {
    .moreproduct_list {
      .swiper_navigations {
        right: 1%;
      }
    }
  }
}

@media (max-width: 1281px) {
  .proinner_sec1 {
    .slider {
      .main_img_wrap {
        margin-top: -2em;
      }
    }
    .text_container {
      padding-top: 4em;
    }
  }
}

@media (max-width: 1194px) {
  .proinner_sec1 {
    .slider {
      .main_img_wrap {
        margin-top: -3.6em;
      }
    }
  }
}

@media (max-width: 1024px) {
  .proinner_sec1 {
    .slider {
      .main_img_wrap {
        margin-top: -4.6em;
      }
    }
  }
  .proinner_sec3 {
    .form_backimg {
      right: 0;
    }
  }
}

@media (max-width: 992px) {
  .proinner_sec1 {
    .text_container {

      .product_desc {
        font-size: 1.4em;
      }

      .product_features__heading {
        font-size: 1.4em;
      }

      .product_features {
        font-size: 1.4em;
      }

      .cta_wrapper {
        position: relative;
      }
    }
    .slider {
      .main_img_wrap {
        margin-top: -5.6em;
      }
    }
  }

  .proinner_sec2 {
    .morepro_heading {
      font-size: 3em;
    }

    .moreproduct_list {
      .product_details_container {
        .imageanimate {
          .content-overlay {
            display: none;
          }
        }
        .product_detail_wrapper {
          max-width: 100%;
        }

        .product_name {
          font-size: 1.8em;
        }

        .product_synopsis {
          font-size: 1.4em;
        }
      }

      .swiper_navigations {
        .nav_icons {
          max-width: 4em;
        }
      }
    }
  }

  .proinner_sec3 {
    padding: 7.063em 0 0;

    .background {
      text-align: center;
    }

    // .form_backimg {
    //   right: -6%;
    // }

    .form_img {
      // max-width: 59.688em;
      max-height: 100%;
    }

    .text_container {
      margin-top: 4em;

      .form_heading {
        font-size: 3em;
      }

      .form_desc {
        font-size: 1.4em;
        max-width: 100%;
      }
    }

    .form_container {
      .material-textfield {
        .mobile_no_flex {
          .no_box {
            padding-right: 15px;
            max-width: 100%;

            input {
              max-width: 95%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 835px) {
  .proinner_sec1 {
    .slider {
      .main_img_wrap {
        margin-top: 0;
      }
    }
  }
}

// @media (max-width: 790px) {
//   .proinner_sec3 {
//     .form_backimg {
//       right: -3%;
//     }
//   }
// }

@media (max-width: 767px) {
  .proinner_sec1 {
    margin: 3em 0;

    .slider {
      padding: 0.5em 1em;

      .slider__flex {
        flex-direction: column-reverse;
      }

      .slider__col {
        flex-direction: row;
        align-items: center;
        margin-right: 0;
        margin-top: 24px;
        width: 100%;
      }

      .main_img_wrap {
        height: 100%;
        width: 100%;
        min-width: 100%;
      }

      .slider__thumbs {
        height: 100%;
        width: 100%;
        margin: 0;
        .slider__image {
          width: 10.96em;
          height: 10.339em;
        }
      }

      .swiper_navigations {
        .nav_icons {
          max-width: 3em;
        }

        .slider__prev {
          left: -0.8em;
        }

        .slider__next {
          right: -0.8em;
        }
      }
    }

    .text_container {

      .product_code {
        font-size: 1.6em;
      }

      .product_desc {
        font-size: 1.3em;
        line-height: 1.7em;
      }

      .product_features__heading {
        font-size: 1.3em;
      }

      .product_features__list {
        margin-left: 1.15em;

        &::before {
          font-size: 2em;
        }
      }

      .product_features {
        font-size: 1.3em;
      }

      .cta_wrapper {
        margin: 3em 0;
      }
    }
  }

  .proinner_sec2 {
    margin-bottom: 1em;

    .morepro_heading {
      font-size: 2.4em;
      text-align: center;
    }

    .moreproduct_list {
      margin-top: 1.2em;

      .swiper_navigations {
        justify-content: center;
        position: relative;

        .nav_icons {
          max-width: 4.5em;
          margin-bottom: 2.9em;
        }
      }

      .product_details_container {
        margin-top: 0 !important;
        margin-bottom: -2em !important;
        .product_name {
          margin-top: 1em;
        }

        .product_synopsis {
          line-height: 1.7em;
          margin-top: 0.6em;
          margin-bottom: 0.6em;
        }

        .product_cta_wrapper {
          margin-bottom: 0;
        }
      }
    }
  }

  .proinner_sec3 {
    padding: 0 0 3.875em;

    .form_backimg {
      display: none;
    }

    .form_checkbox {
      align-items: flex-start;
      .check_box_selector {
        margin-top: 0.25em;
      }
    }

    .my_container {
      padding: 0;
    }

    .background {
      padding: 0;

      position: relative;
      // background-image: url("../../images/ProductInner/Group 3151@2x.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .form_img {
      // visibility: hidden;
      max-width: 100%;
      overflow-x: hidden;
    }

    .text_container {
      text-align: center;

      .form_heading {
        font-size: 2.4em;
      }

      .form_desc {
        line-height: 1.7em;
        font-size: 1.3em;
      }
    }

    .form_container {
      .no_padding {
        padding: 0;
      }

      input {
        font-size: 1.4em;
      }

      label {
        font-size: 1.3em;
      }

      .enquiry_text::placeholder {
        font-size: 0.85em;
      }

      .input {
        font-size: 1.3em;
      }

      .cta_wrapper {
        display: flex;
        justify-content: center;
        margin: 5.6em 0 3.2em 0;
      }
    }
    .check_box_selector:focus + .check_box_label {
      font-size: 1.3em;
    }
  }
}
