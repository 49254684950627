@import "../../common.scss";
.common_btn {
  @include textStyles(1.1em, 1em, 0.2px);
  width: 100%;
  max-width: 13.063em;
  height: 3.2em;
  padding: 1em 1em;
  outline: 0;
  background-color: transparent;
  text-transform: capitalize;
  opacity: 1;
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2em;
  box-shadow: 0 4px 12px var(--shadow);
  transform: translateY(var(--y));
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  &:hover {
    --y: -2px;
    box-shadow: 0 8px 16px var(--shadow);
  }
  &:active {
    --y: 1px;
    box-shadow: 0 4px 8px var(--shadow);
  }
}

.orange_filling {
  background-color: $color-secondary;
  color: #fff !important;
  transition: 0.3s all;
}
.removebg {
  background-color: transparent !important;
  color: #fff !important;
}
.orange_filling:hover {
  // background-color: $color-primary;
  // color: $color-secondary;
  color: #fff !important;
}
.white_filling {
  transition: 0.3s all;
  background-color: #fff;
  color: #231f20;
}
.white_filling:hover {
  background-color: #231f20;
  color: #fff;
}
@media (max-width: 767px) {
  .common_btn {
    @include textStyles(1.4em, 1.143em, 2.8px);
    width: 100%;
    max-width: 9.929em;
    height: 3.071em;
  }
}
