@import "../../common.scss";

.common_banner {
  position: relative;
  margin-top: 7em;
  .disabled_link {
    pointer-events: none;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    position: absolute;
    opacity: 0.1;
    top: 0;
    left: 0;
  }
  .common_banner_img {
    width: 100%;
    max-height: 17.438em;
    object-fit: cover;
  }
  .breadcrumb_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 100%;
  }
  .common_breadcrumb_link {
    @include textStyles(1.111em, 0.842em, 1.6px);
    @include fontStyles("Poppins", 400);
    color: #fff;
    opacity: 1;
    text-decoration: none;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
  .common_breadcrumb_line2 {
    @include textStyles(2.5em, 1.175em, 0.45px);
    @include fontStyles("Poppins", 600);
    margin: 0.5em 0 0;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 0.9;
  }
}

@media (max-width: 1920px) {
  .common_banner {
    margin-top: 4.5em;
  }
}
@media (max-width: 1600px) {
  .common_banner {
    margin-top: 5.6em;
  }
}

@media (max-width: 1500px) {
  .common_banner {
    margin-top: 6.1em;
  }
}
@media (max-width: 1400px) {
  .common_banner {
    margin-top: 6.8em;
  }
}
@media (max-width: 1300px) {
  .common_banner {
    margin-top: 7.5em;
  }
}

@media (max-width: 1024px) {
  .common_banner {
    .common_banner_img {
      min-height: 20em;
    }
  }
}

@media (max-width: 1023px) {
  .common_banner {
    margin-top: 8.2em;
  }
}
@media (max-width: 992px) {
  .common_banner {
    margin-top: 7.3em;
  }
}
@media (max-width: 767px) {
  .common_banner {
    .common_breadcrumb_link {
      @include textStyles(1.4em, 1.5em, 1.4px);
    }
    .common_breadcrumb_line2 {
      @include textStyles(2em, 1.3em, normal);
      width: 100%;
      max-width: 80%;
      margin: 0 auto;
      margin: 0.4em auto 0;
      text-align: center;
    }
    .common_banner_img {
      min-height: 22em;
    }
  }
}
