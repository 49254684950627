@import "../../common.scss";

.aboutus_sec1 {
  position: relative;
  margin: 7em 0 3em;
  .hollow_title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12.063em;
    margin-left: -0.3em;
  }
  .aboutus_back {
    z-index: -1;
    position: absolute;
    top: -7em;
    right: -6em;
    max-width: 35em;
  }
  .imagecenter {
    padding: 0;
  }
  .imageanimate {
    overflow: hidden;
    border-radius: 4px;
    max-width: 95%;
    z-index: 9;
    height: 100%;
    .aboutus_right {
      z-index: 0;
      width: 100%;
      height: 100%;
      transform: scale(1.2);
      transition: 0.3s ease-in-out;
      &:hover {
        transform: scale(1);
      }
    }
  }
  .aboutus_box_content {
    z-index: 1;
    position: absolute;
    // bottom: 2em;
    bottom: 1.5em;
    // left: -6.5em;
    left: -3.5em;
    max-width: 15em;
    .aboutus_box {
      max-width: 12em;
    }
    .aboutus_box_desc {
      z-index: 1;
      position: absolute;
      top: 2em;
      text-align: center;
      color: #fff;
      left: 1.8em;
      // @include textStyles(1.5em, 1.5em, 0.24px);
      @include textStyles(1.2em, 1.2em, 0.24px);
      text-transform: capitalize;
      font-weight: 600;
      opacity: 1;
    }
    .aboutus_box_year {
      // @include textStyles(2.188em, 1.5em, 0.24px);
      @include textStyles(1.8em, 1.2em, 0.24px);
      font-weight: 600;
      z-index: 1;
      position: absolute;
      bottom: 1.2em;
      text-align: center;
      color: #fff;
      left: 2.1em;
    }
  }
  .text_container {
    margin-bottom: 6em;
    .about_heading {
      @include textStyles(2.5em, 1.5em, 0.45px);
      margin-top: 0;
      margin-bottom: 0.5em;
      font-weight: 600;
      max-width: 80%;
      text-transform: capitalize;
      opacity: 1;
      span {
        font-weight: bold;
        color: #fe0103;
      }
    }
    .about_desc {
      @include textStyles(1.125em, 1.7em, 0.24px);
      color: $color-primary;
      opacity: 0.8;
      max-width: 82%;
    }
    .para_img {
      margin-top: 3.5em;
      display: flex;
      align-items: center;
      .desc_img {
        max-width: 5em;
        margin-right: 1.5em;
      }
      .about_desc2 {
        @include textStyles(1.125em, 1.7em, 0.24px);
        padding-top: 1em;
        color: $color-primary;
        opacity: 0.8;
        max-width: 70%;
      }
    }
  }
}

.aboutus_sec2 {
  margin: 3em 0;
  .left_section {
    ul {
      padding: 0;
    }
    .lefttab {
      // @include textStyles(2.25em, 1.5em, 0px);
      @include textStyles(1.6em, 1.2em, 0px);
      text-transform: capitalize;
      color: $color-white;
      font-weight: 600;
      //   background-color: $color-primary;
      background: url("../../images/aboutus/Group\ 3172@2x.png");
      // background-size: contain;
      background-size: cover;
      opacity: 1;
      cursor: pointer;
      // padding: 1.2em 2em;
      padding: 0.4em 2em;
      margin: 1.1em 4em 1.1em 0em;
    }
    .lefttab.active {
      color: $color-white;
      background: url("../../images/aboutus/Group\ 3171@2x.png");
      box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
      // background-size: contain;
      background-size: cover;
      font-weight: 600;
      position: relative;
      opacity: 1;
      transition: 0.3s all;
      // padding: 1.2em 2em;
      padding: 0.5em 2em;
      .arrow_right {
        display: inline;
      }
    }
  }
  .rightbox {
    padding: 1em 3em 1em 0em;
    .right_heading {
      @include textStyles(2.5em, 1.5em, 0.45px);
      font-weight: 600;
      color: $color-primary;
      text-transform: capitalize;
      opacity: 1;
      max-width: 80%;
      margin-bottom: 1em;
    }
    .right_desc {
      @include textStyles(1.125em, 1.7em, 0.24px);
      color: $color-primary;
      opacity: 0.8;
      max-width: 96%;
      margin-bottom: 2em;
    }
    .right_desc2 {
      @include textStyles(1.125em, 1.7em, 0.24px);
      color: $color-primary;
      opacity: 0.8;
      max-width: 96%;
    }
  }
  .arrow_right {
    display: none;
    float: right;
    max-width: 1.5em;
    margin-top: 0.3em;
  }
}

@media (max-width: 1600px) {
  .aboutus_sec1 {
    .text_container {
      margin-bottom: 2em;
    }
    .aboutus_box_content {
      bottom: 7em;
      // bottom: 13em;
    }
  }
}
@media (max-width: 1460px) {
  .aboutus_sec1 {
    .text_container {
      margin-bottom: 5em;
    }
    .aboutus_box_content {
      // bottom: 10.5em;
      left: -6em;
    }
  }
}

@media (max-width: 1390px) {
  .aboutus_sec1 {
    .text_container {
      margin-bottom: 6.75em;
    }
    .aboutus_box_content {
      // bottom: 9em;
    }
  }
}
@media (max-width: 1290px) {
  .aboutus_sec1 {
    .text_container {
      margin-bottom: 5em;
    }
    .aboutus_box_content {
      // bottom: 10em;
    }
  }
}
@media (max-width: 992px) {
  .aboutus_sec1 {
    margin: 4em 0;
    .hollow_title {
      margin-left: 0;
      font-size: 11em;
    }
    .text_container {
      margin-bottom: 3em;
      .about_heading {
        font-size: 2.5em;
        max-width: 95%;
      }
      .about_desc {
        max-width: 95%;
      }
      .para_img .about_desc2 {
        max-width: 95%;
      }
    }
    .imagecenter {
      text-align: center;
    }
    .aboutus_back {
      top: -4em;
      right: -5em;
      max-width: 40em;
    }
    .imageanimate {
      max-width: 100%;
      .aboutus_right {
        transform: scale(1);
        padding: 0 3em;
      }
    }
    .aboutus_box_content {
      left: -1em;
    }
  }
  .aboutus_sec2 {
    .left_section {
      .lefttab {
        padding: 0.7em 0.5em;
        margin: 1.1em 0em 1.1em 0em;
      }
      .lefttab.active {
        padding: 0.7em 0.5em;
        margin: 1.1em 0em 1.1em 0em;
      }
      .arrow_right {
        max-width: 1.5em;
      }
    }
    .rightbox {
      padding-left: 1em;
      .right_heading,
      .right_desc,
      .right_desc2 {
        max-width: 95%;
      }
    }
  }
}

@media (max-width: 800px) {
  .aboutus_sec1 {
    .aboutus_back {
      right: -2em;
    }
  }
}
@media (max-width: 767px) {
  .aboutus_sec1 {
    text-align: center;
    .hollow_title {
      font-size: 6.9em;
    }
    .aboutus_box_content {
      bottom: 1em;
      .aboutus_box {
        max-width: 12.5em;
      }
      .aboutus_box_desc {
        font-size: 1.3em;
        top: 1.2em;
        left: 1.6em;
      }
      .aboutus_box_year {
        font-size: 1.8em;
        left: 2.15em;
      }
    }
    .col-lg-7 {
      padding: 0;
    }
    .imagecenter {
      padding-right: 0;
      padding-left: 15px;
    }
    .imageanimate {
      .aboutus_right {
        transform: scale(1);
        max-width: 30em;
        float: right;
        padding: 0;
      }
    }
    .aboutus_back {
      top: -2.5em;
      max-width: 25em;
    }
    .aboutus_back {
      right: -1em;
    }
    .text_container {
      margin-bottom: 5em;
      margin-top: -1em;
      .about_heading {
        font-size: 2.4em;
        max-width: 100%;
        margin-top: 0;
        span {
          font-size: 1em;
        }
      }
      .about_desc {
        line-height: 1.7em;
        letter-spacing: 0.24px;
        font-size: 1.3em;
        max-width: 100%;
        padding: 0 0.8em;
      }
      .para_img {
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1.5em;
        .desc_img {
          margin-right: 0;
          margin-bottom: 1em;
        }
        .about_desc2 {
          line-height: 1.7em;
          letter-spacing: 0.24px;
          font-size: 1.3em;
          max-width: 100%;
          padding: 0 0.8em;
        }
      }
    }
  }

  .aboutus_sec2 {
    .mb_faq {
      ul {
        padding-left: 0;
        letter-spacing: 2.8px;
      }
      text-align: center;
      .mb_main_li {
        text-align: left;
        text-transform: capitalize;
        font-size: 1.6em;
        color: $color-white;
        background: url("../../images/aboutus/Group\ 3172@2x.png");
        background-size: contain;
        letter-spacing: 0px;
        opacity: 1;
        line-height: 1em;
        padding: 2em 2em 2em 1em;
        margin: 1em 0em 1em 0em;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0);
        letter-spacing: normal;
        transition: 0.3s all;
      }
      .mb_main_li.active {
        color: $color-white;
        background: url("../../images/aboutus/Group\ 3171@2x.png");
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
        background-size: contain;
        position: relative;
        opacity: 1;
        transition: 0.3s all;
        .arrow_right {
          display: inline;
        }
      }

      .mb_faq_sub_menu--list_wrapper {
        display: none;
      }
      .mb_faq_sub_menu--list_wrapper.active {
        display: block;
      }
      .faq_desc {
        display: none;
      }
      .faq_desc.active {
        display: block;
      }
      .rightdesc.active {
        margin-right: 0;
      }
    }
    .rightbox {
      padding: 1em 0.2em 1em 0.2em;
      .right_heading {
        max-width: 100%;
        font-size: 2.4em;
        line-height: 1.5em;
      }
      .right_desc,
      .right_desc2 {
        letter-spacing: 0.24px;
        line-height: 1.7em;
        max-width: 100%;
        font-size: 1.3em;
      }
    }
  }
}
@media (max-width: 380px) {
  .aboutus_sec1 {
    .aboutus_box_content {
      .aboutus_box {
        max-width: 12.5em;
      }
      .aboutus_box_desc {
        font-size: 1.3em;
        top: 1.2em;
        left: 1.6em;
      }
      .aboutus_box_year {
        font-size: 1.8em;
      }
    }
    .aboutus_right {
      max-width: 28em;
      float: right;
    }
  }
}
