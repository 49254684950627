.common_know_more {
  font-size: 1.25em;
  line-height: 1.188em;
  letter-spacing: 3.2px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 0.2em;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none !important;
  transition: 0.3s opacity;
  letter-spacing: 0px;
  opacity: 1;
  color: #171717;
  box-shadow: 0 4px 12px var(--shadow);
  transform: translateY(var(--y));
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    --y: -2px;
    box-shadow: 0 8px 16px var(--shadow);
  }

  &:active {
    --y: 1px;
    box-shadow: 0 4px 8px var(--shadow);
  }

  .right_arrow {
    margin-left: 0.8em;
    max-width: 1.7em;
  }

  .arrow_image {
    content: url("../../images/General/arrow\ black.svg");
    display: inline;
    margin-left: 0.8em;
    max-width: 1.7em;
    align-items: center;
    // &:hover {
    //   content: url("../../images/General/arrow\ orange.svg");
    // }
  }
}

.common_know_more:hover {
  opacity: 1;
  color: #fe0103 !important;
  font-weight: 700;

  .arrow_image {
    transition: all 0.3s ease-in-out;
    content: url("../../images/General/arrow\ orange.svg");
  }
}

@media (max-width: 992px) {
  .common_know_more {
    font-size: 1.5em;
  }
}

@media (max-width: 767px) {
  .common_know_more {
    font-size: 1.4em;
    line-height: 1.5em;
    letter-spacing: 0px;
  }
}