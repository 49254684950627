@import "../../common.scss";

.sticky_sec1 {
  position: fixed;
  top: 85%;
  right: 3em;
  z-index: 5;
  .sticky_img {
    width: 100%;
    // max-width: 6.0625em;
    max-width: 5.25em;
  }
}

@media (max-width: 767px) {
  .sticky_sec1 {
    top: 90%;
    right: 1em;
    .sticky_img {
      max-width: 4.5em;
    }
  }
}
