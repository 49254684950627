@import "../../common.scss";
.footer {
  margin-top: -1px;
  padding-top: 4.389em;
  background-color: #171717;
  position: relative;

  .footeruparrow {
    position: absolute;
    top: -2.563em;
    right: 6.438em;
    width: 100%;
    max-width: 5.125em;
    cursor: pointer;
  }
  .footer_first_row {
    padding-bottom: 1.333em;
  }
  .social_links_wrapper {
    margin-top: 1.625em;
  }
  .social_img {
    width: 100%;
    max-width: 2.146em;
    &:hover {
      transform: scale(1.1);
    }
  }
  .social_link {
    margin-left: 1.242em;
  }
  .social_link:nth-child(1) {
    margin-left: 0;
  }
  .footer_logo {
    width: 100%;
    max-width: 13.188em;
  }
  .box_title {
    @include textStyles(1.5em, 1.5em, 0.24px);
    @include fontStyles("Poppins", 600);
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 1em;
  }
  .box_content_wrapper {
    color: #fff;
    margin-top: 1em;
  }
  .link_name_wrapper {
    margin-top: 0.4em;
  }
  .link_name {
    @include textStyles(1.125em, 1.875em, 0px);
    @include fontStyles("Poppins", 400);
    text-transform: capitalize;
    color: #fff;
    opacity: 0.8;
    text-decoration: none;
    &:hover {
      font-weight: 500;
      opacity: 1;
    }
  }
  .footer_contact_title {
    @include textStyles(1.125em, 1.167em, 0.9px);
    @include fontStyles("Poppins", 500);
    color: #fff;
    margin-top: 1.5em;
  }
  .footer_contact_detail_box {
    margin: 1em 0;
  }
  // .contact_1 {
  //   padding-left: 3.15em;
  // }
  .footer_contact_detail_box:nth-child(1) {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .footer_contact_title {
    margin-top: 0;
  }
  .footer_contact_detail_box:nth-child(2) {
    margin-top: 0;
  }
  .footer_contact_desc {
    @include textStyles(1em, 1.875em, 0.8px);
    @include fontStyles("Work Sans", 500);
    margin-top: 1em;
    color: #fff;
    opacity: 0.8;
    max-width: 55%;
    &:hover {
      opacity: 1;
    }
  }
  .call_icon {
    max-width: 2em;
    margin-right: 2em;
    padding-top: 0.5em;
  }
  .whatsapp_icon {
    max-width: 2em;
    margin-right: 2em;
  }
  .social_icon {
    img {
      margin-top: 1em;
      max-width: 2em;
      cursor: pointer;
      box-shadow: 0 4px 12px var(--shadow);
      transform: translateY(var(--y));
      transition: box-shadow 0.3s ease, transform 0.3s ease;
      &:hover {
        --y: -2px;
        box-shadow: 0 8px 16px var(--shadow);
      }
      &:active {
        --y: 1px;
        box-shadow: 0 4px 8px var(--shadow);
      }
    }
    .fb_icon {
      margin-right: 1.5em;
    }
  }
  .footer_contact_desc_address {
    @include textStyles(1.125em, 1.875em, 0px);
    @include fontStyles("Poppins", 400);
    margin: 1.5em 0;
    color: #fff;
    opacity: 0.8;
    max-width: 60%;
  }
  .footer_contact_desc_contact {
    @include textStyles(1.313em, 1.875em, 0px);
    @include fontStyles("Poppins", 500);
    color: #fff;
    display: inline;
    opacity: 0.8;
    max-width: 55%;
    &:hover {
      opacity: 1;
    }
  }

  .footer_contact_desc,
  .footer_contact_desc_contact a {
    color: #fff;
    text-decoration: none;
    &:hover {
      font-weight: 600;
      opacity: 1;
    }
  }
  .footer_second_row {
    padding-top: 2em;
    padding-bottom: 2em;
    background: #000000;
    .uparrow_img {
      display: none;
    }
    .second_row_content {
      color: #fff;
      @include textStyles(0.875em, 1.143em, 0.7px);
      @include fontStyles("Work Sans", 500);
    }
    .privacy_link {
      opacity: 0.8;
      color: #fff;
      text-decoration: none;
    }
    .copyright_text {
      opacity: 0.8;
    }
  }
}
/*============================
    Desktop CSS Starts Here   
=============================*/

// @media(min-width:  1280px) {}
// @media (min-width: 2560px) {
//   .footer {
//     padding-bottom: 2.875em;
//   }
// }
// @media(max-width: 1919px) {}
// @media(max-width: 1536px) {}
// @media(max-width: 1440px) {}
// @media(max-width: 1366px) {}
// @media(max-width: 1280px) {}
/*============================
Desktop CSS Ends Here   
=============================*/

/*================================
Ipad/Mobile CSS Starts Here   
================================*/
// @media(max-width: 1194px) {}
// @media(max-width: 1024px) {}
@media (max-width: 1023px) {
  .footer {
    padding-top: 6em;
    text-align: center;
    .footeruparrow {
      max-width: 4em;
      top: -2em;
      right: 1.4em;
    }
    .box_content_wrapper {
      display: none;
    }
    .box_content_active.box_content_wrapper {
      display: block;
    }
    .plus_span {
      padding-left: 0.5em;
      font-size: 1.3em;
    }
    .social_img {
      max-width: 2.4em;
    }
    .footer_logo {
      max-width: 10em;
    }
    .col-lg-3:nth-child(n + 2) {
      margin-top: 1.2em;
    }
    .box_title,
    .footer_contact_title {
      @include textStyles(1.8em, 1.188em, 0.8px);
    }
    .link_name,
    .footer_contact_desc {
      @include textStyles(1.3em, 1.923em, 0.65px);
    }
    .social_links_wrapper {
      margin-top: 2.9em;
      margin-bottom: 0.7em;
    }
    .footer_second_row {
      // margin-bottom: 1em;
      .copyright_text {
        margin-top: 1em;
      }
      .second_row_content {
        @include textStyles(1.1em, 1.182em, 0.55px);
      }
    }
  }
}
@media (max-width: 992px) {
  .footer {
    .footer_contact_desc {
      max-width: 100%;
      a {
        color: #fff;
      }
    }
    .copyright_text,
    .privacy_link {
      margin-bottom: 1.5em;
    }
    .footer_contact_details_container {
      display: inline;
      .social_icon {
        img {
          margin-top: 2em;
          max-width: 3em;
        }
      }
    }
  }
}
@media (max-width: 835px) {
  .footer {
    .contact_1 {
      padding-left: 0;
      // padding-left: 4.4em;
    }
  }
}
@media (max-width: 767px) {
  .footer {
    .footer_contact_desc {
      max-width: 100%;
      @include textStyles(1.3em, 1.923em, 0.24px);
      font-weight: 400;
      a {
        color: #fff;
      }
    }
    .footer_contact_desc,
    .footer_contact_desc_contact a {
      &:hover {
        font-weight: 400;
        opacity: 0.8;
      }
    }
    .footer_contact_detail_box {
      margin: 1em 0 4.5em;
    }
    .box_title {
      padding-top: 0.7em;
    }
    .copyright_text,
    .privacy_link {
      font-weight: 400;
      letter-spacing: 0.5px;
      font-size: 1.182em;
      margin-bottom: 1.5em;
    }
    .footer_second_row {
      position: relative;
      .uparrow_img {
        display: block;
        position: absolute;
        right: 2em;
        top: -2em;
        z-index: 2;
        max-width: 4.5em;
        cursor: pointer;
      }
    }
    .link_name_wrapper {
      margin-top: 0.75em;
    }
  }
  .call_icon,
  .whatsapp_icon {
    max-width: 1.8em;
  }
}
// @media(max-width: 375px) {}
// @media(max-width: 320px) {}
/*================================
Ipad/Mobile CSS Ends Here   
================================*/
