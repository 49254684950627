@import "../../common.scss";

.ourpro_sec1 {
  margin-top: 2.833em;

  .search_sec {
    // display: flex;
    margin-bottom: 4em;
    .reset_btn {
      // margin-left: 1.5em;
      // color: #fe0103;
      // border: 1px solid #fe0103;
      // border-radius: 50px;
      // padding: 0 1.9933em 0;
      // font-size: 1.25em;
      background-color: #fff;
      border: none;
      cursor: pointer;
      margin-right: 1.667em;
      padding: 0;
      width: 100%;
      max-width: 1.333em;
      .reset_img {
        width: 100%;
        max-width: 1.333em;
      }
    }
  }

  .my_container {
    padding-left: 3em;
  }

  .search_form {
    padding-left: 2.063em;
    width: 100%;
    max-width: 29.938em;
    height: 4.5em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.56);
    border-radius: 40px;

    .search_icon {
      width: 100%;
      max-width: 4.5em;
      border-radius: 50%;
      color: #000;
      cursor: pointer;
    }

    .search_input {
      border: none;
      font-size: 1em;
      line-height: 1.7em;
      letter-spacing: 0.1em;
      text-transform: capitalize;
      font-family: "Poppins";
      font-weight: 400;
      color: #333333;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .category_btns_wrapper {
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    // margin: 4em 0;
    margin: 0 0 4em;
    padding-bottom: 1em;

    &::-webkit-scrollbar {
      height: 5px;
      background: #aca5a59c;
    }

    &::-webkit-scrollbar-thumb {
      background: #3b3a3a9c;
    }

    .category_btn {
      font-size: 1.25em;
      white-space: nowrap;
      padding-bottom: 0.3em;
      font-weight: 500;
      opacity: 1;
      text-transform: capitalize;
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0em;
        // width: 2.625em;
        width: 0;
        height: 2px;
        background-color: $color-secondary;
        transition: 0.3s width;
      }

      &:nth-child(n + 2) {
        margin-left: 2.667em;
      }
    }

    .category_btn.active {
      color: $color-secondary;
      font-weight: bold;
      opacity: 1;

      &::after {
        width: 4.625em;
      }
    }

    .category_btn:hover {
      color: $color-secondary;
      opacity: 1;
    }

    a:focus {
      color: $color-secondary;
      text-decoration: none;
      font-weight: 700;
      border-bottom: 2px solid $color-secondary;
    }
  }

  .dropdown_flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: 4.5em;
    position: relative;
    z-index: 2;
    width: 100%;
    // margin: 3em 0 4.5em;
    margin: 0 0 4.5em;

    .dropdown_text {
      @include textStyles(1.5em, 1.7em, 0.24px);
      @include fontStyles("Poppins", 500);
      color: #333333;
      margin-right: 1em;
      text-transform: capitalize;
    }

    .dropdown_select {
      width: 100%;
      max-width: 12.938em;
      border: none;
      border-bottom: 1px solid #333333;
      @include textStyles(1.5em, 1.9em, 0.24px);
      @include fontStyles("Poppins", 700);
      color: #333333;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-bottom: 0.2em;
      background-color: transparent;
      cursor: pointer;
      text-transform: capitalize;
    }

    .dropdown_select:focus {
      outline: none;
    }

    .downdropdownarrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 3.5em;
    }
  }
}

.ourpro_sec2 {
  .my_container {
    padding-left: 3em;
  }

  .product_details_container {
    padding: 0;
  }

  .product_detail_wrapper {
    width: 100%;
    max-width: 23.813em;
    position: relative;
    overflow: hidden;
  }

  .product_detail_wrapper::before {
    content: "";
    width: 100%;
    background-color: #000;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: -2;
    transition: 0.3s opacity;
  }

  .imageanimate {
    overflow: hidden;
    border-radius: 3px;

    .content-overlay {
      overflow: hidden;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      height: 100%;
      width: 100%;
      max-width: 23.438em;
      max-height: 22.034em;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.4s ease-in-out 0s;
      -moz-transition: all 0.4s ease-in-out 0s;
      transition: all 0.4s ease-in-out 0s;
      border-radius: 3px;
    }

    &:hover .content-overlay {
      opacity: 1;
    }
  }

  .product_img {
    width: 100%;
    max-width: 23.438em;
    max-height: 22.034em;
    border-radius: 3px;
    position: relative;
    z-index: 0;
  }

  .text_container {
    bottom: -100%;
    left: 2.688em;
    z-index: 3;
    transition: 0.3s bottom;
  }

  .text_wrapper {
    height: 100%;
    min-height: 6.2em;
  }

  .product_category {
    font-size: 1em;
    text-transform: uppercase;
    opacity: 0.7;
    color: #333333;
    margin-top: 2.556em;
    font-weight: 600;
  }

  .product_name {
    @include textStyles(1.333em, 1.5em, 0.24px);
    @include fontStyles("Poppins", 600);
    color: #333333;
    text-transform: capitalize;
    opacity: 1;
    // margin-top: 2.556em;
    margin-top: 0.3751em;
  }

  .product_synopsis {
    display: none;
    @include textStyles(1.125em, 1.7em, 0.24px);
    font-family: "Poppins";
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    width: 100%;
    color: #333333;
    opacity: 0.8;
  }

  .product_detail_wrapper:hover::before {
    opacity: 0.8;
  }

  .product_detail_wrapper:hover .text_container {
    bottom: 3.875em;
  }

  .product_cta_wrapper {
    // margin-top: 1.6em;
    // color: #FE0103;
    // font-size: 1em;
    margin-top: 0em;
    margin-bottom: 6.25em;
  }

  .horizontal_line {
    border-top: 1px solid black;
    margin-bottom: 3em;
    opacity: 0.35;
  }

  .loadmore_cta_wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 3.625em;
    text-decoration: underline;

    .load_more_cta {
      width: 100%;
      max-width: 6em;
      cursor: pointer;
      font-size: 1.25em;
      font-family: "Poppins";
      font-weight: 500;

      &:hover {
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 2561px) {
  .ourpro_sec1 {
    .hollow_title {
      // z-index: -3;
      position: absolute;
      left: -2.1em;
      top: 1.75em;
      font-size: 13.063em;
      transform: rotate(-90deg);
    }
  }

  .ourpro_sec2 {
    overflow: hidden;

    // .imageanimate {
    //   .content-overlay {
    //     max-height: 22.5em;
    //   }
    // }

    .hollow_title {
      // z-index: -3;
      position: absolute;
      right: -2.05em;
      bottom: 2.6em;
      font-size: 13.063em;
      transform: rotate(90deg);
    }
  }
}

@media (max-width: 1024px) {
  .ourpro_sec1,
  .ourpro_sec2 {
    .my_container {
      padding-left: 15px;
    }
  }

  .ourpro_sec2 {
    .imageanimate {
      .content-overlay {
        display: none;
      }
    }
  }
}

@media (max-width: 992px) {
  .ourpro_sec2 {
    .text_wrapper {
      height: 100%;
      min-height: 4.5em;
    }
  }
}

@media (max-width: 835px) {
  .ourpro_sec1,
  .ourpro_sec2 {
    // padding-left: 4em;
    .product_detail_wrapper {
      max-width: 29em;
    }
  }

  .ourpro_sec1 {
    .hollow_title {
      top: 0;
      left: 0;
      display: flex;
      text-align: center;
      justify-content: center;
      font-size: 10em;
      position: relative;
      transform: rotate(0deg);
    }

    // .category_btns_wrapper {
    //   .category_btn {
    //     max-width: 20%;
    //   }
    // }
  }

  .ourpro_sec2 {
    .hollow_title {
      display: none;
    }

    .product_img {
      max-width: 100%;
      transform: scale(1);
    }
  }
}

@media (max-width: 992px) {
}
@media (max-width: 767px) {
  .ourpro_sec1 {
    text-align: center;
    margin-top: 1em;

    .search_sec {
      // flex-direction: column;
      margin-bottom: 3em;
      margin-top: 1.6em;
      .reset_btn {
        // margin-left: 0;
        // margin-top: 2.4em;
        // height: 3.6666666em;
        // font-size: 1.5em;
        width: 100%;
        max-width: 1.6em;
        margin-right: 2em;
        .reset_img {
          width: 100%;
          max-width: 1.6em;
        }
      }
    }

    .hollow_title {
      top: 0.4em;
      left: 0;
      display: flex;
      text-align: center;
      justify-content: center;
      font-size: 6em;
      position: relative;
      transform: rotate(0deg);
      z-index: -1;
    }

    .search_form {
      background-color: #fff;
      max-width: 100%;
      border-radius: 100px;
      height: 5.5em;

      .search_icon {
        max-width: 5.5em;
      }
    }

    .category_btns_wrapper {
      display: none;
    }
    .dropdown_flex {
      .dropdown_select {
        padding-right: 1.5em;
        max-width: 14.2em;
      }
      .downdropdownarrow {
        right: 0.5em;
      }
    }
  }

  .ourpro_sec2 {
    .product_name {
      font-size: 1.6em;
      margin-top: 1.8em;
    }

    .product_synopsis {
      font-size: 1.3em;
      margin-top: 0.6em;
      margin-bottom: 0.6em;
    }

    .product_cta_wrapper {
      // font-size: 1.4em;
      margin-bottom: 2.25em;
    }

    .product_img {
      border-radius: 6px;
      max-height: 100%;
    }

    .product_detail_wrapper {
      max-width: 100%;
    }

    .horizontal_line {
      display: none;
    }

    .loadmore_cta_wrapper {
      .load_more_cta {
        font-size: 1.4em;
      }
    }
    .product_category {
      font-size: 1.4em;
    }
    .text_wrapper {
      min-height: 100%;
    }
  }
}

@media (max-width: 370px) {
  .ourpro_sec1 {
    .dropdown_flex {
      .dropdown_text {
        font-size: 1.3em;
      }
    }
  }
}

@media (max-width: 353px) {
  .ourpro_sec1 {
    .dropdown_flex {
      .dropdown_text {
        font-size: 1.2em;
      }
    }
  }
}
