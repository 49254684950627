@import "../../common.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background: transparent;
  transition: 0.1s all ease-in-out;
  padding: 0em 0;
  .nav_icon {
    width: 2.188em;
    height: 2.188em;
    position: relative;
    z-index: 2;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    span {
      display: block;
      width: 100%;
      height: 0.2em;
      background-color: #fff;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    &.open_nav {
      transform: translateY(-0.5em);
    }
    &.open_nav span:nth-child(1) {
      top: 18px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: translateY(1.2em) rotate(135deg);
    }

    &.open_nav span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    &.open_nav span:nth-child(3) {
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: translateY(-0.28em) rotate(-135deg);
    }
  }
  .route_link {
    color: $color-white;
    text-decoration: none;
    transition: 0.3s all;
    padding-top: 10px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      transition: transform 0.5s ease;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: $color-secondary;
      transform: scaleX(0);
    }
    &:hover:before {
      transform: scaleX(1);
      color: $color-secondary;
    }
  }
  .route_link_black {
    color: #000 !important;
    text-decoration: none;
    transition: 0.3s all;
    &:hover {
      color: $color-secondary !important;
    }
  }
  .link_selected {
    font-weight: 600;
    text-decoration: underline;
  }

  .Navbarclass {
    display: flex;
    height: 80px;
    width: 100%;
    // background: #171717;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.5em;
    border: none;
    text-decoration: none;
    outline: none;
    position: relative;
    &:hover {
      cursor: default;
    }
    .nav_logo_img {
      width: 100%;
      max-width: 8.188em;
      position: absolute;
      left: 1%;
      // left: 10%;
      top: 50%;
      transform: translate(0%, -50%);
      // transform: translate(-30%, -50%);
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      list-style: none;
      outline: none;
      border: none;
      margin: 0;
      transition: all 0.3s ease;
      .dropdown {
        .dropdown-menu {
          // opacity: 0;
          top: 96%;
          opacity: 1;
          display: none;
          // pointer-events: none;
        }
        &:hover {
          .dropdown-menu {
            display: block;
            // opacity: 1;
            padding: 1em 2em 1em 1em;
            pointer-events: all;
            transition: all 0.2s ease-in-out;
          }
          .dropdown-toggle::after {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transition: all 0.3s ease;
          }
        }

        &::after {
          display: none;
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 20px solid #fff;
          position: absolute;
          bottom: -22%;
          left: 10%;
          z-index: 9999;
          // &:hover {
          //   .dropdown-menu {
          //     &::after {
          //       display: block;
          //     }
          //   }
          // }
        }

        &:hover {
          &::after {
            display: block;
          }
        }

        .dropdown-item {
          background: transparent;
          @include textStyles(0.938em, 1.5em, 0px);
          font-weight: 500;
          position: relative;
          text-transform: capitalize;
          &:before {
            content: "";
            position: absolute;
            transition: transform 0.5s ease;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: $color-secondary;
            transform: scaleX(0);
          }
          &:hover:before {
            // transform: scaleX(1);
            color: $color-secondary;
          }
        }
        // .ourprod_link {
        //   pointer-events: none;
        // }
      }
      .dropdown-toggle {
        margin-right: 1em;
        &::after {
          border: solid #fff;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          position: absolute;
          top: 0.85em;
          right: 0;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          transition: all 0.3s ease;
        }
        &:hover {
          color: $color-white;
        }
      }
    }
    ul li {
      margin: 0 5px;
    }
    ul li a {
      @include textStyles(1.25em, 1.5em, 0px);
      color: $color-white;
      text-decoration: none;
      outline: none;
      // font-size: 18px;
      font-weight: 500;
      padding: 0.5em 0.938em;
      border-radius: 5px;
      transition: all 0.3s ease;
    }
    ul li a.active,
    ul li a:hover {
      color: $color-white;
      opacity: 1;
    }
    ul li a:focus {
      outline: none;
    }
    .catalogue_btn {
      background-color: $color-secondary;
      padding: 1em 2em;
      border-radius: 50px;
      border: none;
      .route_link:hover {
        color: #fff;
      }
    }
    .menu-btn i {
      color: $color-white;
      font-size: 22px;
      cursor: pointer;
      display: none;
    }
    input[type="checkbox"] {
      display: none;
    }
  }
}
.navbar_bg {
  background-color: #171717;
}
/*============================
    Desktop CSS Starts Here   
=============================*/

// @media(min-width:  1280px) {}
@media (min-width: 1921px) {
  .header {
    .Navbarclass {
      ul {
        .dropdown-toggle::after {
          top: 1em;
        }
        .dropdown-toggle {
          margin-right: 0em;
        }
      }
    }
  }
}
@media (max-width: 1920px) {
  .header {
    .Navbarclass {
      ul {
        .dropdown-toggle::after {
          top: 1em;
        }
        .dropdown-toggle {
          margin-right: 0.5em;
        }
      }
    }
  }
}

// @media (max-width: 1536px) {}
// @media (width: 1536px) {}
// @media (max-width: 1440px) {}
@media (max-width: 1366px) {
  .header {
    .Navbarclass {
      ul {
        .dropdown-toggle::after {
          top: 0.85em;
        }
      }
    }
  }
}
// @media(max-width: 1280px) {}
/*============================
Desktop CSS Ends Here   
=============================*/

/*================================
Ipad/Mobile CSS Starts Here   
================================*/
// @media(max-width: 1194px) {}
// @media (max-width: 1024px) {}
@media (max-width: 1023px) {
  .header {
    padding: 0.5em 0;
    background: #171717;
    .mb_nav_flex {
      min-height: 6.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mb_nav_logo_img {
        width: 100%;
        max-width: 7.5em;
        position: relative;
        z-index: 5;
      }
    }
    .mb_menu {
      position: fixed;
      top: 0;
      left: -150%;
      width: 100%;
      height: 100vh;
      background-color: #171717;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      transition: 0.3s all;
    }
    .mb_menu.mb_menu_active {
      left: 0;
    }
    .mb_links_wrapper_cont {
      height: 50em;
      width: 100%;
      // overflow-y: scroll;
      .catalogue_btn {
        background-color: $color-secondary;
        padding: 0.8em 2em;
        border-radius: 50px;
        border: none;
        font-size: 1.6em;
        .route_link:hover {
          color: #000;
        }
      }
    }
    .mb_link_wrapper {
      .mb_route_text {
        @include textStyles(1.6em, 1.905em, 0);
        @include fontStyles("Poppins", 400);
        text-transform: capitalize;
        color: $color-white;
        text-decoration: none;
      }
      .mb_sub_route_text {
        @include textStyles(1.4em, 1.875em, 0);
        @include fontStyles("Poppins", 400);
        text-transform: capitalize;
        opacity: 0.8;
      }
      .mb_link_main_wrapper {
        position: relative;
        width: fit-content;
        margin: 0em auto 2em;
        .plus_minus_span {
          position: absolute;
          top: 40%;
          transform: translateY(-50%);
          right: -1em;
          color: #fff;
          font-size: 2.5em;
          font-weight: 600;
        }
      }
      .mb_sub_menu_wrapper {
        display: none;
        .mb_sub_link_wrapper {
          padding-bottom: 1.5em;
        }
      }
      .mb_sub_menu_wrapper.sub_menu_active {
        display: block;
      }
    }
  }
}
// @media(max-width: 834px) {}
// @media(max-width: 767px) {}
// @media(max-width: 375px) {}
// @media(max-width: 320px) {}
/*================================
Ipad/Mobile CSS Ends Here   
================================*/
/*
This is the extracted copied part responsible for showing & hiding the submenu.
*/
