@import "../../common.scss";

.home_sec1 {
  // .explore_wrapper {
  //   position: relative;

  //   .explorelogo {
  //     position: absolute;
  //     width: 100%;
  //     z-index: 1;
  //     max-width: 5.944em;
  //     right: 8.563em;
  //     bottom: -3em;
  //     cursor: pointer;
  //   }
  // }

  .row {
    background: url("../../images/Homepage/Group 3207.jpg") center / cover
      no-repeat;
    margin: 0;
  }

  .first_container {
    // background: url("../../images/Homepage/leftbackground.png");
    // object-fit: cover;
    color: $color-white;
    background: #171717;
    z-index: 0;
    // margin-left: 7em;

    .first_heading {
      text-align: left;
      font-size: 3em;
      font-weight: 600;
      letter-spacing: 0.55px;
      color: $color-white;
      text-transform: capitalize;
      opacity: 0.9;
      padding-top: 3.5em;
      // margin-left: 2.1em;
      // max-width: 75%;
      max-width: 85%;
    }

    .first_desc {
      @include textStyles(1.2em, 1.5em, 0.24px);
      font-weight: 300;
      color: $color-white;
      opacity: 0.8;
      // margin: 1.5em 0 3em 5.8em;
      margin: 1.5em 0 3em 0;
      max-width: 70%;
    }

    // .cta_wrapper {
    //   margin-left: 7em;
    // }

    .social_icon {
      // margin-left: 7em;
      z-index: 5;

      img {
        // margin-top: 12em;
        // margin-bottom: 4.667em;
        margin-top: 5em;
        margin-bottom: 3em;
        max-width: 2.833em;
        cursor: pointer;
        box-shadow: 0 4px 12px var(--shadow);
        transform: translateY(var(--y));
        transition: box-shadow 0.3s ease, transform 0.3s ease;

        &:hover {
          --y: -2px;
          box-shadow: 0 8px 16px var(--shadow);
        }

        &:active {
          --y: 1px;
          box-shadow: 0 4px 8px var(--shadow);
        }
      }

      .fb_icon {
        margin-right: 1.5em;
      }
    }
  }
}

.explore_wrapper {
  position: relative;

  .explorelogo {
    position: absolute;
    width: 100%;
    z-index: 1;
    max-width: 5.944em;
    right: 8.563em;
    bottom: -3em;
    cursor: pointer;
  }
}

.home_sec1_mob {
  background-color: #171717;
  text-align: center;

  .first_container_mob {
    // background: url("../../images/Homepage/Mask\ Group\ 1.png");
    object-fit: cover;
    z-index: 2;
    color: $color-white;
    position: relative;

    .first_heading {
      z-index: 2;
      font-size: 2.6em;
      font-weight: 600;
      letter-spacing: 0.55px;
      color: $color-white;
      text-transform: capitalize;
      opacity: 1;
      margin-top: 2em;
      margin: 0em 1em 0;
      padding-top: 4.5em;
    }

    .first_desc {
      z-index: 2;
      font-size: 1.3em;
      letter-spacing: 0px;
      color: $color-white;
      opacity: 0.8;
      padding: 1em 2em;
    }

    .cta_wrapper {
      z-index: 2;
      justify-content: center;
      display: flex;
      padding-top: 2em;
      padding-bottom: 5em;
      min-width: 4em;

      .common_btn {
        z-index: 2;
        color: #fff;
        opacity: 1;
        letter-spacing: 0.2px;
        max-width: 10.929em;
      }
    }

    .social_icon {
      margin-left: 7.7em;

      img {
        margin-top: 12em;
        margin-bottom: 4.667em;
        max-width: 3.833em;
      }
    }
  }

  // .explore_wrapper {
  //   position: relative;

  //   .explorelogo {
  //     position: absolute;
  //     width: 100%;
  //     z-index: 5;
  //     max-width: 6.944em;
  //     right: 1.563em;
  //     bottom: -3.5em;
  //     cursor: pointer;
  //   }
  // }
}

.explore_wrapper {
  position: relative;

  .explorelogo {
    position: absolute;
    width: 100%;
    z-index: 5;
    max-width: 6.944em;
    right: 1.563em;
    bottom: -3.5em;
    cursor: pointer;
  }
}

.home_sec2 {
  margin: 5em 0 0;
  position: relative;

  .hollow_title {
    line-height: 0.3em;
  }

  .back_img {
    z-index: -1;
    max-width: 40.5em;
  }

  .imageanimate {
    overflow: hidden;
    border-radius: 3px;
    max-width: 36.556em;
    z-index: 9;
    top: 0;
    left: -2em;
    position: absolute;

    .aboutus_img {
      z-index: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      max-width: 35.556em;
      text-align: right;
      left: -2em;
      top: 0;
      transform: scale(1.2);
      transition: 0.3s ease-in-out;

      &:hover {
        transform: scale(1);
      }
    }
  }

  .description_box {
    margin-top: 2em;

    .aboutus_intro {
      font-size: 2.813em;
      display: inline;
      letter-spacing: 0.45px;
      font-weight: 600;
    }

    .aboutus_intro_orange {
      display: inline;
      font-weight: bold;
      color: $color-secondary;
    }

    .aboutus_desc {
      @include textStyles(1.125em, 1.7em, 0.24px);
      margin-top: 2em;
      font-weight: normal;
      color: #333333;
      max-width: 82%;
    }
  }

  .video_icon,
  .video_title {
    display: none;
  }

  .cta_wrapper {
    margin-top: 2.7em;
    color: $color-white;

    button {
      outline: none;
      border: none;
    }

    .readmore {
      @include textStyles(1em, 1.5em, 0.2px);
      width: 100%;
      max-width: 12.5em;
      // height: 3.5em;
      height: 3em;
      padding: 1em 0em;
      outline: 0;
      background-color: transparent;
      text-transform: capitalize;
      opacity: 1;
      text-decoration: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 2em;
      color: $color-white;
      background-color: $color-secondary;
      transition: all 0.3s ease-in;
      box-shadow: 0 4px 12px var(--shadow);
      transform: translateY(var(--y));
      transition: box-shadow 0.3s ease, transform 0.3s ease;

      &:hover {
        --y: -2px;
        box-shadow: 0 8px 16px var(--shadow);
      }

      &:active {
        --y: 1px;
        box-shadow: 0 4px 8px var(--shadow);
      }

      img {
        margin-left: 1em;
        max-width: 1.8em;
      }
    }
  }
}

.home_sec3 {
  background-color: #171717;
  color: $color-white;
  padding-top: 4.556em;
  padding-bottom: 6.278em;
  position: relative;

  .hollow_title {
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #fff;
    position: absolute;
    left: -2.08em;
    top: 2.2em;
    font-size: 13.063em;
    transform: rotate(-90deg);
  }

  .ourproduct_box {
    margin: 0 24em;

    .ourpro_heading {
      text-align: center;
      font-size: 2.813em;
      letter-spacing: 0.45px;
      margin-bottom: 1em;
    }

    .ourpro_desc {
      @include textStyles(1.125em, 1.7em, 0.24px);
      text-align: center;
      opacity: 0.8;
      font-weight: 300;
    }

    .cta_wrapper {
      margin-top: 2.7em;
      color: $color-white;
      align-items: center;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        outline: none;
        border: none;
      }

      .readmore {
        @include textStyles(1em, 1.5em, 0.2px);
        align-items: center;
        width: 100%;
        max-width: 12.5em;
        height: 3em;
        padding: 1em 0em;
        outline: 0;
        background-color: transparent;
        text-transform: capitalize;
        opacity: 1;
        text-decoration: none !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 2em;
        color: $color-white;
        background-color: $color-secondary;
        transition: all 0.3s ease-in;
        box-shadow: 0 4px 12px var(--shadow);
        transform: translateY(var(--y));
        transition: box-shadow 0.3s ease, transform 0.3s ease;

        &:hover {
          --y: -2px;
          box-shadow: 0 8px 16px var(--shadow);
        }

        &:active {
          --y: 1px;
          box-shadow: 0 4px 8px var(--shadow);
        }

        img {
          margin-left: 1em;
          max-width: 1.8em;
        }
      }
    }
  }

  .category_btns_wrapper {
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin: 4em 0 0 12em;

    &::-webkit-scrollbar {
      display: none;
      height: 2px;
      background: #171717;
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
      display: none;
    }

    .category_btn {
      font-size: 1.313em;
      letter-spacing: 0.24px;
      line-height: 1em;
      font-weight: normal;
      white-space: nowrap;
      margin-right: 1.2em;
      padding-bottom: 1em;
      opacity: 0.6;
      background-color: transparent;
      color: $color-white;
      outline: none;
      text-transform: capitalize;
      position: relative;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0em;
        width: 0;
        height: 2px;
        background-color: #ffffff;
        transition: 0.3s width;
      }

      &:nth-child(n + 2) {
        margin-left: 2.667em;
      }
    }

    .category_btn.active {
      color: #ffffff;
      font-weight: bold;
      opacity: 1;

      &::after {
        width: 3.625em;
      }
    }

    .category_btn:hover {
      color: #ffffff;
      opacity: 1;
    }

    a:focus {
      color: #ffffff;
      text-decoration: none;
      font-weight: 700;
      border-bottom: 2px solid #ffffff;
    }
  }

  .products_slide_wrapper {
    margin: 4em auto 0;
    padding-left: 12em;
    position: relative;

    .desc_wrapper {
      padding: 0;

      .tab_desc {
        @include textStyles(1.125em, 1.7em, 0.24px);
        max-width: 89%;
        font-weight: 300;
        color: #ffffff;
        text-align: left;
        opacity: 0.8;
      }

      .leftbox_wrap {
        height: 0;
        transition: transform 0.6s ease-out; // note that we're transitioning transform, not height!
        transform-origin: top;
        // transform: scaleY(0); // implicit, but good to specify explicitly
      }
      .leftbox_wrap.active {
        height: auto;
        // transform: scaleY(1);
      }

      .cta_wrapper {
        margin-top: 2.7em;
        color: $color-white;

        button {
          outline: none;
          border: none;
        }

        .readmore {
          @include textStyles(1.2em, 1em, 0.2px);
          padding: 0;
          width: 100%;
          max-width: 9.5em;
          outline: 0;
          background-color: transparent;
          text-decoration: none !important;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          border-radius: 2em;
          color: $color-white;
          transition: all 0.3s ease-in;
          box-shadow: 0 4px 12px var(--shadow);
          transform: translateY(var(--y));
          transition: box-shadow 0.3s ease, transform 0.3s ease;

          &:hover {
            --y: -2px;
            box-shadow: 0 8px 16px var(--shadow);
          }

          &:active {
            --y: 1px;
            box-shadow: 0 4px 8px var(--shadow);
          }

          img {
            margin-left: 1em;
            max-width: 1.8em;
          }
        }
      }

      .arrows_wrapper {
        max-width: 8em;
        position: absolute;
        content: "";
        bottom: 0;
        left: 0em;
        z-index: 2;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;

        .leftarrowimg {
          margin-right: 1em;
          max-width: 3.75em;
        }

        .rightarrowimg {
          max-width: 3.75em;
        }
      }
    }

    .product_details_container {
      padding: 0;

      .product_detail_wrapper {
        width: 100%;
        max-width: 26em;
        position: relative;
        overflow: hidden;
      }

      .product_detail_wrapper::before {
        content: "";
        width: 100%;
        background-color: #000;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: -2;
        transition: 0.3s opacity;
      }

      .imageanimate {
        overflow: hidden;
        border-radius: 3px;

        .content-overlay {
          overflow: hidden;
          background: rgba(0, 0, 0, 0.6);
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
          -webkit-transition: all 0.4s ease-in-out 0s;
          -moz-transition: all 0.4s ease-in-out 0s;
          transition: all 0.4s ease-in-out 0s;
          border-radius: 3px;
        }

        &:hover .content-overlay {
          opacity: 1;
        }

        &:hover .product_img {
          transform: scale(1.2);
        }
      }

      .product_img {
        position: relative;
        text-align: left;
        display: flex;
        max-width: 26em;
        transform: scale(1);
        transition: 0.3s ease-in-out;
      }

      .text_container {
        bottom: -100%;
        left: 2.688em;
        z-index: 3;
        transition: 0.3s bottom;
      }

      .product_name {
        @include textStyles(1.5em, 1.7em, 0.24px);
        position: relative;
        margin-top: 1.2em;
        text-align: left;
        font-weight: 500;
        color: $color-white;
        text-transform: capitalize;
      }

      .product_synopsis {
        @include textStyles(1.125em, 1.7em, 0.24px);
        margin-top: 1em;
        text-align: left;
        max-width: 100%;
        font-weight: 300;
        color: $color-white;
        opacity: 0.8;
        // max-height: 8em;
        max-height: calc(1.7em * 4);
        margin-bottom: 0;
        overflow: hidden;
        ul {
          padding-left: 1em;
          overflow: hidden;
          li {
            &::before {
              content: "•";
              position: absolute;
              color: $color-secondary;
              font-weight: 700;
              display: inline-block;
              width: 0.6em;
              margin-left: -0.7em;
              font-size: 1.3em;
            }
          }
        }
      }

      .product_detail_wrapper:hover::before {
        opacity: 0.8;
      }
    }

    .productswiper {
      height: 0;
      transition: transform 0.6s ease-out; // note that we're transitioning transform, not height!
      transform-origin: top;
      // transform: scaleY(0); // implicit, but good to specify explicitly
    }
    .productswiper.active {
      height: auto;
      // transform: scaleY(1);
    }
  }
}

.home_sec4 {
  padding: 3em 0 4em;

  .col-lg-5 {
    text-align: center;
    position: relative;

    .imageanimate {
      overflow: hidden;
      border-radius: 3px;
      max-width: 42em;
      max-height: 45em;
      z-index: 9;
      top: 2em;
      right: 0;
      position: absolute;

      .how_img {
        position: relative;
        top: 0;
        right: 0;
        z-index: 0;
        max-width: 100%;
        // transform: scale(1.2);
        transition: 0.3s ease-in-out;

        // &:hover {
        //   transform: scale(1.2);
        // }
      }
    }
  }

  .col-lg-7 {
    padding-left: 7em;
    overflow-x: hidden;
  }

  .hollow_title {
    // line-height: 0.7em;
    line-height: 0.7em;
    position: absolute;
    right: -0.1em;
    letter-spacing: 2.09px;
    // font-size: 13.063em;
    font-size: 12em;
    // color: #D4D4D4;
    // -webkit-text-fill-color: #D4D4D4;
  }

  // .hollow_title:hover{
  //   // -webkit-text-fill-color: #D4D4D4;
  //   background-image: linear-gradient( to right, #222222, #222222 50%, #ffffff 50% );
  //   background-size: 200% 100%;
  //   background-position: 100%;
  //   transition: all 3s cubic-bezier(0, 0, 0.23, 1);
  //   -webkit-text-stroke-width: 0.5px;
  //   -webkit-text-stroke-color: #a6a6a6;
  // }

  .sec4_back {
    z-index: -1;
    max-width: 32em;
    position: absolute;
    left: 0;
    top: -3em;
  }

  .description_box {
    // margin-top: 7.7em;
    margin-top: 7.4em;

    .howare_intro {
      @include textStyles(2.813em, 1.5em, 0.45px);
      display: inline;
      font-weight: 600;
    }

    .howare_intro_orange {
      display: inline;
      font-weight: bold;
      color: $color-secondary;
    }

    .howare_desc {
      @include textStyles(1.125em, 1.7em, 0.24px);
      margin-top: 1em;
      font-weight: normal;
      color: #333333;
      max-width: 90%;
    }
  }

  .counter_wrapper_flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1.4em 0em;
    background-color: #fff;
    justify-content: space-between;

    .counter_cont {
      max-width: 45%;
      margin: 0em 1em 1.4em 0em;
      padding-right: 4em;
    }
  }
}

// Brand Section moved after banner
.home_sec5 {
  padding: 5em 0 3em;
  position: relative;
  background: rgba(0, 0, 0, 0.04);

  .description_box {
    margin-top: 2em;

    .brand_intro {
      @include textStyles(2.813em, 1.2em, 0.45px);
      display: block;
      font-weight: 600;
      max-width: 85%;
    }

    .brand_intro_orange {
      display: inline;
      font-weight: bold;
      color: $color-secondary;
    }

    .brand_desc {
      margin-top: 2em;
      @include textStyles(1.125em, 1.7em, 0.24px);
      color: #333333;
      max-width: 79%;
    }
  }

  .brand_swiper_wrapper {
    .swiper-container {
      appearance: none;
      -webkit-appearance: none;
    }
    .swiper_box {
      // text-align: center;
      .brand_img {
        width: 100%;
        max-width: 17em;
        // height: 100%;
        min-width: 17em;
        padding: 0 1em;
        // min-height: 18.75em;
        // max-width: 18.75em;
        // max-height: 18.75em;
      }

      .brand_name {
        text-align: center;
        margin-top: 1.2em;
        @include textStyles(1.5em, 1.5em, 0.24px);
        font-weight: 500;
        color: #000;
        text-transform: capitalize;
      }
    }
  }
}

// @media (min-width: 2500px) {
//   .home_sec1 {
//     .first_container {
//       margin-left: 8.35em;
//     }
//   }
// }

@media (max-width: 2560px) {
  .home_sec3 {
    .productswiper {
      .product_desc {
        max-width: 100%;
      }

      .imageanimate {
        .content-overlay {
          max-width: 26em;
          max-height: 24.5em;
        }
      }
    }
  }
}

// @media (max-width: 1919px) {
//   .home_sec1 {
//     .first_container {
//       margin-left: 9em;
//     }
//   }
// }

@media (max-width: 1600px) {
  .home_sec2 {
    .back_img {
      max-width: 40em;
    }
  }

  .home_sec3 {
    .productswiper {
      .product_desc {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1445px) {
  .home_sec3 {
    .productswiper {
      .product_desc {
        max-width: 100%;
      }
    }
  }
}

// @media (max-width: 1536px) {
//   .home_sec1 {
//     .first_container {
//       margin-left: 7.4em;
//     }
//   }
// }

@media (max-width: 1380px) {
  // .home_sec1 {
  //   .first_container {
  //     margin-left: 3.9em;
  //   }
  // }
  .home_sec2 {
    .back_img {
      max-width: 36.5em;
    }
  }
}

// @media (max-width: 1280px) {
//   .home_sec1 {
//     .first_container {
//       margin-left: 7em;
//     }
//   }
// }

// @media (max-width: 1160px) {
//   .home_sec1 {
//     .first_container {
//       margin-left: 5em;
//     }
//   }
// }

// @media (max-width: 1110px) {
//   .home_sec1 {
//     .first_container {
//       margin-left: 2.25em;
//     }
//   }
// }

// @media (max-width: 1024px) {
//   .home_sec1 {
//     .first_container {
//       margin-left: 1em;
//     }
//   }
// }

@media (max-width: 1024px) {
  .home_sec3 {
    .productswiper {
      .imageanimate {
        .content-overlay {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .home_sec1_mob {
    .first_container_mob {
      .text_container {
        z-index: 5;
      }

      .first_desc {
        padding: 1em 7em;
      }
    }
  }

  .home_sec2 {
    margin: 5em 0;

    .back_img {
      max-width: 100%;
      z-index: -1;
    }

    .cta_wrapper {
      margin-bottom: 3em;
    }

    .imageanimate {
      max-width: 100%;
      left: 0em;
      z-index: 0;

      .aboutus_img {
        transform: scale(1);
        max-width: 100%;
        z-index: 0;
        left: 0em;
      }
    }

    .hollow_title {
      margin-top: 0.5em;
    }
  }

  .home_sec3 {
    .ourproduct_box {
      margin: 0 12em;

      .ourpro_heading {
        line-height: 2em;
      }
    }
    .productswiper {
      .product_desc {
        max-width: 85%;
      }

      .product_img {
        transform: scale(1);
      }
    }

    .category_btns_wrapper {
      margin: 4em 0 0 9em;

      .category_btn {
        margin-bottom: 1em;
      }
    }

    .products_slide_wrapper {
      margin: 3em auto 0;
      padding-left: 9em;

      .desc_wrapper {
        margin-bottom: 5em;
      }

      .product_details_container {
        .product_img {
          transform: scale(1);
        }
      }
    }
  }

  .home_sec4 {
    padding: 3.111em 0 0;

    .col-lg-5 {
      .imageanimate {
        position: relative;
        max-width: 100%;

        .how_img {
          transform: scale(1);
          max-width: 52em;
          margin-left: 0;
        }
      }
    }

    .sec4_back {
      left: 4em;
      min-width: 63em;
      max-height: 40em;
    }

    .hollow_title {
      padding: 0;
      margin-top: 0.5em;
      margin-left: 0.15em;
    }

    .description_box {
      margin-left: 3em;
    }

    .counter_wrapper_flex {
      padding-left: 3em;
    }
  }

  .home_sec5 {
    .description_box {
      .brand_intro {
        max-width: 100%;
      }

      .brand_desc {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 991px) {
  .home_sec3 {
    .products_slide_wrapper {
      .desc_wrapper {
        margin-bottom: 0;
        .arrows_wrapper {
          position: relative;
          top: initial;
          left: initial;
          margin: 2.5em 0 2.5em;
        }
      }
    }
  }
}

@media (max-width: 835px) {
  .home_sec1_mob {
    .second_container_mob {
      .homepage_mobimg {
        width: 100%;
        max-width: 70%;
      }
    }
  }
  .home_sec3 {
    // .products_slide_wrapper {
    // .desc_wrapper {
    //   margin-bottom: 9em;
    // .arrows_wrapper {
    //   top: 16em;
    // }
    // }
    // }
    .productswiper_2 {
      max-width: 95%;
    }
  }
}

@media (max-width: 800px) {
  .home_sec4 {
    .sec4_back {
      min-width: 60em;
    }
  }
}

// @media (max-width: 778px) {
//   .home_sec3 {
//     .products_slide_wrapper {
//       .desc_wrapper {
//         margin-bottom: 9em;
//         .arrows_wrapper {
//           top: 17.5em;
//         }
//       }
//     }
// }
// }

@media (max-width: 767px) {
  .home_sec1_mob {
    .first_container_mob {
      .first_desc {
        line-height: 1.7em;
        padding: 1em 2em;
        letter-spacing: 0.24px;
      }
    }

    .second_container_mob {
      .homepage_mobimg {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .home_sec2 {
    .hollow_title {
      margin-top: 0.2em;
      font-size: 6.9em;
      line-height: 0.5em;
      position: absolute;
      left: -0.2em;
    }

    .description_box {
      margin: 4em 0 4em;
      text-align: center;

      .aboutus_intro {
        font-size: 2.4em;
        font-weight: bolder;
      }

      .aboutus_desc {
        line-height: 1.7em;
        font-size: 1.3em;
        max-width: 100%;
        letter-spacing: 0.24px;
      }
    }

    .cta_wrapper {
      display: flex;
      justify-content: center;
      margin: 4em 0;

      .readmore {
        padding: 1em 2em;
      }
    }

    .video_icon {
      display: block;
      position: absolute;
      z-index: 0;
      bottom: -1.6em;
      right: 0;
      max-width: 16em;
    }

    .video_title {
      display: block;
      position: absolute;
      z-index: 0;
      bottom: -1.2em;
      right: 1.6em;
      max-width: 16em;
      font-size: 1.3em;
      font-weight: bold;
    }
  }

  .home_sec3 {
    padding-bottom: 1em;

    .col-md-7 {
      padding: 0;
    }

    .hollow_title {
      position: relative;
      transform: rotate(0deg);
      font-size: 6.9em;
      left: 0;
      top: 0;
      text-align: center;
      line-height: 0.5em;
    }

    .ourproduct_box {
      margin: 0 1em;

      .ourpro_heading {
        font-size: 2.4em;
      }

      .ourpro_desc {
        line-height: 1.7em;
        font-size: 1.3em;
        padding: 0 1em;
        letter-spacing: 0.24px;
      }

      .cta_wrapper {
        .readmore {
          background: none;

          &:hover {
            background: none;
            color: #fff;
            opacity: 1;
          }
        }
      }
    }

    .category_btns_wrapper {
      margin: 4em 15px 0 15px;
      overflow: auto;

      .category_btn {
        margin: 0 0 1em 0;
        padding-bottom: 1.375em;
        text-align: left;
        font-size: 1.6em;
        transition: all 0.3s ease-in-out;
      }
    }
    .productswiper_2 {
      margin: 3em auto 0;
    }
    .products_slide_wrapper {
      margin: 3em auto 0;
      padding-left: 15px;

      .leftbox_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .tab_desc {
          line-height: 1.7em;
          max-width: 100%;
          padding: 0 1.5em 0 0.5em;
          text-align: center;
          font-size: 1.3em;
          font-weight: 300;
        }
      }

      .product_details_container {
        .imageanimate {
          img {
            max-width: 100%;
          }
        }

        .product_detail_wrapper {
          max-width: 100%;
        }

        .product_synopsis {
          line-height: 1.7em;
        }
      }
    }

    .arrows_wrapper {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      position: relative;
      margin: 2em 1em 0 0;
      cursor: pointer;

      .leftarrowimg {
        margin-right: 1em;
        max-width: 5em;
      }

      .rightarrowimg {
        max-width: 5em;
      }
    }
    .mb_nav {
      height: 0;
      transition: transform 0.6s ease-out; // note that we're transitioning transform, not height!
      transform-origin: top;
      // transform: scaleY(0); // implicit, but good to specify explicitly
    }
    .mb_nav.active {
      height: auto;
      // transform: scaleY(1);
    }
  }

  .home_sec4 {
    .sec4_back {
      min-width: 32em;
      top: 2em;
      left: 0;
    }

    .col-lg-5 {
      .imageanimate {
        .how_img {
          max-width: 32em;
          margin-left: 0;
          margin-bottom: 2em;
        }
      }
    }

    .description_box_mob {
      margin-top: 1em;
      text-align: center;

      .howare_intro_mob {
        font-size: 2.4em;
        line-height: 1.5em;
        display: inline;
        font-weight: bolder;
      }

      .howare_intro_orange_mob {
        display: inline;
        color: $color-secondary;
      }

      .howare_desc_mob {
        font-size: 1.3em;
        letter-spacing: 0;
        font-weight: normal;
        color: #333333;
        padding: 1em 2em 1em;
        line-height: 1.7em;
      }
    }

    .col-lg-7 {
      padding: 0;
      text-align: center;

      .counter_wrapper_flex {
        display: block;
        padding-left: 0;
        padding-bottom: 0;

        .counter_cont {
          max-width: 100%;
          padding-right: 0;
          margin: 0em 2em 3em 2em;

          .common_actual_count {
            margin-bottom: 0.3em;
          }

          .common_count_title {
            font-size: 1.3em;
            line-height: 1.7em;
            letter-spacing: 0.24px;
          }
        }
      }
    }
  }

  .home_sec5 {
    padding-top: 2.611em;

    .description_box {
      text-align: center;

      .brand_intro {
        font-size: 2.4em;
        font-weight: bolder;
      }

      .brand_desc {
        font-size: 1.3em;
        margin-bottom: 3em;
        letter-spacing: 0.24px;
      }
    }

    .brand_swiper_wrapper {
      // padding-right: 15px;
      .swiper_box {
        .brand_name {
          font-size: 1.6em;
        }
        // .brand_img {
        //   min-width: 17.2em;
        //   min-height: 16.9em;
        //   max-width: 17.2em;
        //   max-height: 16.9em;
        // }
      }
    }
  }
}

@media (max-width: 600px) {
  .home_sec5 {
    .brand_swiper_wrapper {
      padding: 0;
    }
  }
}

@media (max-width: 375px) {
  .home_sec1_mob {
    .second_container_mob {
      .homepage_mobimg {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .home_sec3 {
    .hollow_title {
      font-size: 5.9em;
    }
  }

  .home_sec4 {
    .sec4_back {
      max-width: 30em;
    }

    .col-lg-5 {
      .imageanimate {
        .how_img {
          max-width: 30em;
          margin-bottom: 2em;
        }
      }
    }
  }
}
