@import "../../common.scss";

.newsletter {
  background: url("../../images/General/Group\ 3187@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2em 36em;
  text-align: center;
  box-sizing: border-box;
  position: relative;

  .error {
    text-transform: capitalize;
    position: absolute;
    color: red;
    left: 46%;
  }

  .email_error {
    left: initial;
  }

  .uparrow_img {
    position: absolute;
    right: 8em;
    top: -2.5em;
    z-index: 2;
    max-width: 5.25em;
    cursor: pointer;
  }

  .news_heading {
    @include textStyles(1.87em, 1.4em, 0.24px);
    text-align: center;
    font-weight: 400;
    color: $color-white;
    text-transform: capitalize;
    opacity: 1;
  }

  .text {
    width: 100%;
    height: 4.125em;
    background-color: #fff;
    border-radius: 40px;
    position: relative;
    margin-top: 2.5em;

    input {
      @include textStyles(1.125em, 1.5em, 0);
      width: 100%;
      height: 3.7em;
      border-radius: 40px;
      border: 0;
      background: none;
      padding: 0em 2.875em 0;
      outline: none;
    }

    button {
      background-color: $color-secondary;
      border: 0;
      width: 4.125em;
      height: 4.125em;
      border-radius: 50%;
      position: absolute;
      right: 0px;
      top: 0px;
      outline: none;
      cursor: pointer;
      color: #fff;
      transition: 0.3s linear;
      // &:hover {
      //   background-color: $color-primary;
      //   opacity: 0.7;
      // }
    }

    .arrow_right {
      max-width: 3em;
    }
  }
  .news_check_box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1.5em;
    text-align: start;
    .check_box_selector {
      appearance: auto;
      -webkit-appearance: auto;
      width: initial;
      margin-right: 0.75em;
      margin-top: 0.3em;
      cursor: pointer;
      // vertical-align: sub;
      accent-color: #fe0103;
    }
    .check_box_label {
      position: relative;
      color: #fff;
      vertical-align: 10%;
    }
  }
}

@media (max-width: 1024px) {
  .newsletter {
    .news_check_box {
      .check_box_selector {
        margin-top: 0.3em;
      }
    }
  }
}

@media (max-width: 992px) {
  .newsletter {
    padding: 5em 13em;

    .news_heading {
      @include textStyles(2.4em, 1.3em, 0.24px);
      font-weight: 500;
    }

    .text {
      height: 5.125em;

      input {
        @include textStyles(1.3em, 1.5em, 0);
        height: 3.6em;
        padding: 0.5em 4.875em 0 1.5em;
      }

      button {
        width: 5.125em;
        height: 5.125em;
      }
    }
  }
}

@media (max-width: 767px) {
  .newsletter {
    background: url("../../images/General/Group\ 3225@3x.jpg");
    padding: 0 1.5em;
    height: 53.2em;
    background-size: cover;
    max-height: 53.2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .email_error {
      left: 0;
      right: 0;
    }

   .news_check_box {
    align-items: flex-start;
    .check_box_selector {
      margin-top: 0.4em;
    }
    .check_box_label {
      font-size: 1.2em;
    }
  }
    .uparrow_img {
      display: none;
    }

    .news_heading {
      @include textStyles(2.4em, 1.3em, 0.24px);
      font-weight: 500;
      padding: 0 0.5em;
    }

    .text {
      height: 5.9em;

      input {
        @include textStyles(1.3em, 1.5em, 0);
        height: 4.4em;
        padding: 0 4.875em 0 1.5em;
      }

      button {
        width: 5.9em;
        height: 5.9em;
      }
    }
  }
}