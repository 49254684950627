@import "../../common.scss";

.thankyousec {
  overflow-x: hidden;
  position: relative;
  text-align: center;
  padding: 30em 0 18em;
  .thankyou_container {
    display: flex;
    justify-content: center;
  }
  .hollow_title {
    // font-size: 21.313em;
    font-size: 13.063em;
    position: absolute;
    z-index: 0;
  }
  .thankyouimg1 {
    z-index: 1;
    margin-top: -10.5em;
    margin-bottom: -3em;
    max-width: 7.375em;
  }
  .headp {
    text-align: center;
    .heading {
      margin-top: 0;
      font-size: 6.313em;
      line-height: 1.5em;
      font-weight: 500;
      color: $color-secondary;
      opacity: 1;
      font-weight: 600;
      letter-spacing: 0px;
    }
    .heading2 {
      margin-top: 0;
      font-size: 2.188em;
      line-height: 1.5em;
      font-weight: 500;
      color: $color-primary;
      opacity: 1;
      font-weight: 600;
      letter-spacing: 0.45px;
    }
    p {
      font-size: 1.25em;
      line-height: 1.7em;
      letter-spacing: 0.24px;
      font-weight: 500;
      color: $color-primary;
      text-transform: uppercase;
      opacity: 0.8;
    }
    .link_p {
      font-size: 1.25em;
      line-height: 1.7em;
      letter-spacing: 0.24px;
      font-weight: 500;
      color: $color-primary;
      text-transform: initial;
      opacity: 0.8;
    }
    .mobilep {
      display: none;
    }

    .orange_link {
      color: $color-secondary;
      cursor: pointer;
      &:hover {
        color: $color-secondary;
      }
    }
    .back_button {
      //   position: absolute;
      top: 70%;
      right: 45.5%;
      border: 1px solid #231f20;
      background-color: transparent;
      font-size: 1em;
      line-height: 1.1875em;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      padding: 1em 3em;
    }
  }
}

@media (max-width: 2560px) {
  .thankyousec {
    .hollow_title {
      right: 3em;
    }
  }
}
@media (max-width: 1920px) {
  .thankyousec {
    .hollow_title {
      right: 2em;
    }
  }
}
@media (max-width: 1600px) {
  .thankyousec {
    .hollow_title {
      right: 1.8em;
    }
  }
}
@media (max-width: 1460px) {
  .thankyousec {
    .hollow_title {
      right: 1.9em;
    }
  }
}
// @media (max-width: 1400px) {
//   .thankyousec {
//     .hollow_title {
//       right: 1.9em;
//     }
//   }
// }
// @media (max-width: 1300px) {
//   .thankyousec {
//     .hollow_title {
//       right: 1.85em;
//     }
//   }
// }

@media (max-width: 992px) {
  .thankyousec {
    .hollow_title {
      font-size: 10.063em;
      right: 1em;
    }
  }
}
@media (max-width: 768px) {
  .thankyousec {
    .hollow_title {
      right: 0.6em;
    }
  }
}

@media (max-width: 600px) {
  .thankyousec {
    padding: 33em 0 12em;
    .hollow_title {
      font-size: 6.8em;
      right: 0;
      left: 0;
    }
    .thankyouimg1 {
      margin-top: -16em;
      max-width: 6.8em;
    }
    .headp {
      .heading {
        margin-top: 0.4em;
        font-size: 5.3em;
      }
      .heading2 {
        font-size: 2em;
        margin-bottom: 1.5em;
      }
      p {
        font-size: 1.6em;
        padding: 0 2.5em;
        margin-bottom: 1em;
      }
      .link_p {
        font-size: 1.6em;
        padding: 0;
      }
    }
  }
}

@media (max-width: 374px) {
  .thankyousec {
    .hollow_title {
    }
    .thankyouimg1 {
    }
  }
}
